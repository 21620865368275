<template>
  <VueSlimTable
    ref="table"
    :source="getBusinesses"
    :columns="columns"
    :per-page="perPage"
    class="table mv-businesses-table">
    <template #row="{ row }">
      <tr>
        <td v-for="({ key }, index) in columns" :key="key" :class="{ 'w-55': index === 0, 'w-15': index !== 0 }">
          <template v-if="key === 'name'">
            <div class="d-flex align-items-center gap-3">
              <a :href="getBusinessLink(row.id)" class="image">
                <img v-if="row.logoUrl" :src="row.logoUrl" alt="">
                <div v-else class="nologo">
                  <i class="fa-regular fa-paperclip" />
                  <span>Upload logo</span>
                </div>
              </a>
              <div>
                <div class="d-flex align-items-center gap-2">
                  <template v-if="row.disconnected">
                    <i :id="row.id" class="color-warning far fa-exclamation-circle" />
                    <BTooltip :target="row.id" title="Location is disconnected" />
                  </template>
                  <a :href="getBusinessLink(row.id)">{{ row.name }}</a>
                </div>
                <small class="d-block text-muted">{{ transformLocationData(row.locationData) }}</small>
              </div>
            </div>
          </template>
          <template v-else>
            {{ row[key] }}
          </template>
        </td>
      </tr>
    </template>

    <template #pagination>
      <Pagination class="pb-0"
        :page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @updatePage="updatePage" />
    </template>
  </VueSlimTable>
</template>

<script>
import Pagination from 'vue_widgets/components/pagination'

export default {
  components: { Pagination },
  props: {
    search: { type: String, default: '' }
  },
  data() {
    return {
      totalCount: 0,
      currentPage: 1
    }
  },
  created() {
    this.perPage = 25
    this.columns = [
      { key: 'name', title: 'Business', orderable: true },
      { key: 'employeesCount', title: 'Workers', orderable: false },
      { key: 'checkinsCount', title: 'Moments', orderable: false },
      { key: 'postsCount', title: 'Posts', orderable: false }
    ]
  },
  methods: {
    getBusinesses(prms = {}) {
      return axios.get(
        '/gb/ajax/businesses',
        {
          params: {
            ...prms,
            page: this.currentPage,
            search: this.search
          },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => {
        this.totalCount = parseInt(res.headers['total-count'], 10)
        return res.data
      })
    },
    getBusinessLink(id) {
      return `${window.location.pathname}/${id}`
    },
    transformLocationData(locationData) {
      return Object.values(locationData).filter((val) => Boolean(val)).join(', ')
    },
    updatePage(page) {
      this.currentPage = page
      this.$refs.table.reload()
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.currentPage = 1
        this.$refs.table.refetch()
      }, 500)
    }
  }
}
</script>

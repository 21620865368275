<template>
  <b-row v-if="pageTab === 'new' || pageTab === 'edit'" class='inmap top left'>
    <b-col xs=4 class='pl-0'>
      <div class='form-field'>
        <multiselect
          label='text'
          :options='gridSizes'
          :searchable='false'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :preserve-search='true'
          :show-labels='false'
          v-model='gridSize'>
          <template slot='singleLabel' slot-scope='{ option }'>
            {{ `${option}x${option}` }}
          </template>
          <template slot='option' slot-scope='{ option }'>
            {{ `${option}x${option}` }}
          </template>
        </multiselect>
      </div>
    </b-col>
    <b-col xs=4 class='p-0'>
      <div class='form-field'>
        <div class='radio-buttons'>
          <template v-for='{ value, text } in distanceMeasureOptions'>
            <input
              type='radio'
              :name='`radios-btn-default`'
              :value='value'
              :key='value'
              :id='`${value}`'
              v-model='distanceMeasure'>
            <label class='radio-btn' :for='`${value}`' :key='text+value'>{{ text }}</label>
          </template>
        </div>
      </div>
    </b-col>
    <b-col xs=4 class='pr-0'>
      <div class='form-field'>
        <multiselect
          label='text'
          :options='pointDistances[distanceMeasure]'
          :searchable='false'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :preserve-search='true'
          :show-labels='false'
          v-model='distance' />
      </div>
    </b-col>
    <b-col v-if="isAdmin && pageState === 'live'" xs=4 class='pr-0'>
      <div class='form-field'>
        <multiselect
          label='text'
          track-by='value'
          :options='algorithms'
          :searchable='false'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :preserve-search='true'
          :show-labels='false'
          v-model='algorithm' />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'MapControls',
  components: { Multiselect },
  props: {
    pointDistances: Object,
    distanceMeasureOptions: Array
  },
  data() {
    return {
      gridSizes: [3, 5, 7, 9, 13]
    }
  },
  computed: {
    ...mapState(['pageTab', 'pageState', 'isAdmin', 'algorithms']),
    algorithm: {
      get() { return this.algorithms.find(({ value }) => value === this.$store.state.algorithm) },
      set(val) { this.setAlgorithm(val.value) }
    },
    gridSize: {
      get() { return this.$store.state.gridSize },
      set(val) { this.renderDummyGrid({ gridSize: val }) }
    },
    distanceMeasure: {
      get() { return this.$store.state.distanceMeasure },
      set(val) {
        this.renderDummyGrid({
          gridPointDistance: this.pointDistances[val][0].id,
          gridDistanceMeasure: val,
          saveDisabledPoints: true
        })
      }
    },
    distance: {
      get() {
        return this.pointDistances[this.distanceMeasure]
          .find((distance) => parseFloat(distance.id).toString() === parseFloat(this.$store.state.distance).toString())
      },
      set(val) {
        this.renderDummyGrid({
          gridPointDistance: val.id,
          gridDistanceMeasure: this.distanceMeasure,
          saveDisabledPoints: true
        })
      }
    }
  },
  methods: {
    ...mapMutations(['renderDummyGrid', 'setAlgorithm']),
    renderForm(attributes) {
      this.gridSize = attributes.gridSize
      this.distanceMeasure = attributes.gridDistanceMeasure
      this.setDistance(parseFloat(attributes.gridPointDistance))
    },
    setDistance(value) {
      this.pointDistance = value
      $(`#point-distance-${this.distanceMeasure}`).val(value).trigger('change')
    }
  }
}
</script>

<template>
  <tr>
    <td>
      {{ row.customer }}
    </td>
    <td>
      {{ row.businessUnitName }}
    </td>
    <td>
      {{ row.city }}, {{ row.state }}
    </td>
    <td>
      {{ row.category }}
    </td>
    <td>
      {{ row.jobStatus }}
    </td>
    <td>
      {{ completedAt }}
    </td>
    <td>
      {{ row.tenant }}
    </td>
    <td>
      {{ row.invoicesTotal }}
    </td>
    <td>
      <a :href="`/gb/service_titan/jobs/${row.id}`">
        Edit
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    row: { type: Object, required: true }
  },
  computed: {
    completedAt() {
      if (!this.row.remoteCompletedAt) {
        return '-'
      }
      return (new Date(this.row.remoteCompletedAt)).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h12'
      })
    }
  }
}
</script>

<template>
  <div class="tabs-reusable">
    <div class="tabs-reusable__container">
      <div
        v-for="(tab, index) in list"
        class="tabs-reusable__item"
        :class="{ 'tabs-reusable__item--active': index === activeTab, 'tabs-reusable__item--disabled': disabledTabs.includes(index) }"
        @click="handleTabClick(index)">
        <slot :name="`before:${index}`" :tab="tab" :index="index" />
        <span>{{ tab }}</span>
        <slot :name="`after:${index}`" :tab="tab" :index="index" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: [Array, Object],
      required: true
    },
    activeTab: {
      type: [Number, String],
      required: true
    },
    disabledTabs: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  methods: {
    handleTabClick(index) {
      if (!this.disabledTabs.includes(index)) {
        this.$emit('setActiveTab', index)
      }
    }
  }
}
</script>

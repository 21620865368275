import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'

import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { runSwal } from 'common/delete_with_swal'

import makeImageSelectStore from '../../../components/image_select/store'

import {
  collectVideo, collectImageAndExifs, collectMultilocation, multilocationStore, submitUrl
} from '../../local_posts/form/helpers'

export default function createStore(prms) {
  const imageSelectStore = makeImageSelectStore(prms)

  const store = new Vuex.Store({
    state: {
      previewError: null,
      plainErrors: null,
      lockedSubmit: false,

      ...multilocationStore.state,
      ...imageSelectStore.state,
      ...prms
    },
    getters: {
      ...multilocationStore.getters
    },
    actions: {
      ...multilocationStore.actions,
      ...imageSelectStore.actions,
      onSubmit({ state, commit }, e) {
        $(e.target).toggleWrapper()

        const data = new FormData()
        let request
        if (state.object.obfuscatedId) {
          data.append('gmb_media[id]', state.object.category)
          data.append('gmb_media[scheduled_at]', state.object.scheduledAt || '')

          request = axios.put([submitUrl(state, 'media'), state.object.obfuscatedId].join('/'), data)
        } else {
          data.append('gmb_media[category]', state.object.category)
          data.append('gmb_media[fallback_category]', state.object.fallbackCategory)
          data.append('gmb_media[media_format]', state.object.galleryImage ? 'photo' : 'video')
          if (state.object.scheduledAt) data.append('gmb_media[scheduled_at]', state.object.scheduledAt)

          collectImageAndExifs(state, data, 'gmb_media')
          collectVideo(state, data, 'gmb_media')
          collectMultilocation(state, data)

          request = axios.post(submitUrl(state, 'media'), data)
        }

        request.then(
          (res) => {
            $(document).one('turbolinks:load', () => {
              if (res.data.notice) toastr.success(res.data.notice)
            })
            Turbolinks.visit(res.data.path)
          },
          (err) => {
            $(e.target).toggleWrapper({}, false)
            document.body.scrollIntoView({ behavior: 'smooth' })
            if (err.response && err.response.data) {
              const errData = err.response.data

              if (errData.type === 'sync') {
                commit('updateSyncErrors', errData.errors)
              } else if (errData.type === 'plain') {
                commit('updatePlainErrors', errData.errors)
              } else if (errData.error) {
                toastr.error(errData.error)
              }
            } else {
              toastr.error(DEFAULT_ERROR_MESSAGE)
            }
          }
        )
      },
      onDelete({ state }, e) {
        runSwal(() => {
          const $form = $(e.target.closest('form'))
          $form.toggleWrapper()
          axios.delete(e.target.href)
            .then(
              () => {
                $(document).one('turbolinks:load', () => toastr.success('Media was successfully destroyed'))
                Turbolinks.visit(`/gmb/locations/${state.object.remoteLocationId}/media/${state.object.category}`)
              },
              () => {
                $form.toggleWrapper({}, false)
                toastr.error('Something went wrong!')
              }
            )
        })
      },
      onBack({ state }) {
        if (window.history.length > 1) {
          window.history.back()
        } else {
          window.location.pathname = `/gmb/locations/${state.object.remoteLocationId}/media/identity`
        }
      }
    },
    mutations: {
      ...imageSelectStore.mutations,
      ...multilocationStore.mutations,
      updateSyncErrors(state, obj) {
        state.object.syncErrors = obj
      },
      updatePlainErrors(state, errors) {
        state.plainErrors = errors
      },
      setLockedSubmit(state, value) {
        state.lockedSubmit = value
      },
      updateVideoUrl(state, videoUrl) {
        state.object.videoUrl = videoUrl
      }
    }
  })

  return store
}

<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <InputSearch v-model.trim="searchVal" custom-classes="form-field-md" />
    </div>

    <TableView :search="searchVal" />
  </div>
</template>

<script>
import InputSearch from '../../components/input_search_ui'
import TableView from './index/table_view'

export default {
  components: {
    InputSearch,
    TableView
  },
  data() {
    return {
      searchVal: '',
      searchTimeout: null,
      selectedView: 'table-view'
    }
  },
  created() {
    this.viewTypes = ['table-view', 'list-view', 'card-view']
    this.viewTypeIcons = {
      'table-view': 'table',
      'list-view': 'list',
      'card-view': 'grip-horizontal'
    }
  }
}
</script>

import toastr from 'toastr'
import injectScript from './inject_script'
import { ICONS_PATH } from './constants'

export const MAX_RANK = 20
export const DEFAULT_CENTER = { lat: 0, lng: 0 }
export const DEFAULT_ZOOM = 16
// As far as i can tell, there is no other way to enable
// these controls individually when "disableDefaultUI" is set to "true"

export const MAP_CONTROLS_SETTINGS = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
}

export function renderMarker(lat, lng, map, rank) {
  const marker = new google.maps.Marker({
    position: { lat: lat, lng: lng },
    icon: {
      url: `${ICONS_PATH}${rank}.png`,
      scaledSize: new google.maps.Size(32, 32)
    },
    draggable: false,
    map: map
  })

  marker.setZIndex(rank)

  return marker
}

export function ensureGoogleMap(data, callback) {
  if (typeof google !== 'undefined') {
    callback.call({}, data)
  } else {
    const script = `https://maps.googleapis.com/maps/api/js?key=${data.googleMapApiKey}&libraries=places&language=${data.lang || 'en'}&callback=focus`
    injectScript(script)
      .then(() => {
        callback.call({}, data)
      }).catch((error) => {
        toastr.error('Unexpected error has occurred during Google Map initialization.')
        throw error
      })
  }
}

<template>
  <div class="workday-schedule">
    <div
      v-for="weekday in weekdays"
      :key="weekday"
      class="workday-schedule__item">
      <div class="workday-schedule__line"
        :class="{ 'has-errors': errors[weekday] }">
        <CustomCheckbox
          v-model="mutableValue[weekday].isOpen"
          :id="`${weekday}-is-open`"
          :name="`${name}[${weekday}][is_open]`"
          class="workday-schedule__weekday"
          @input="onChangeOpenState(weekday)">
          <span>{{ capitalize(weekday) }}</span>
        </CustomCheckbox>
        <template v-if="mutableValue[weekday].isOpen">
          <CustomCheckbox
            v-model="mutableValue[weekday].allDay"
            :id="`${weekday}-all-day`"
            label="24"
            :name="`${name}[${weekday}][all_day]`"
            class="workday-schedule__all-day"
            @input="onChangeAllDayState(weekday)" />
          <CustomInput
            v-model="mutableValue[weekday].from"
            type="time"
            class="workday-schedule__time"
            :name="`${name}[${weekday}][from]`"
            :disabled="mutableValue[weekday].allDay"
            @input="emitValue" />
          <CustomInput
            v-model="mutableValue[weekday].to"
            type="time"
            class="workday-schedule__time"
            :name="`${name}[${weekday}][to]`"
            :disabled="mutableValue[weekday].allDay"
            @input="emitValue" />
        </template>
        <span class="workday-schedule__dayoff" v-else>Day off</span>
      </div>
      <small v-if="errors[weekday]" class="text-danger">
        {{ errors[weekday].join(', ') }}
      </small>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'common/helpers'

import CustomCheckbox from './checkbox'
import CustomInput from './custom_input'

const DEFAULT_FROM_HOUR = '09:00'
const DEFAULT_TO_HOUR = '18:00'

export default {
  components: {
    CustomCheckbox,
    CustomInput
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mutableValue: {
        ...this.value
      },
      errors: {}
    }
  },
  created() {
    this.weekdays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ]
  },
  methods: {
    emitValue() {
      this.errors = {}
      this.validateBusinessHours()
      this.$emit('input', this.mutableValue)
    },
    onChangeOpenState(weekday) {
      if (this.mutableValue[weekday].isOpen) {
        this.setDefaultTime(weekday)
        return
      }

      this.mutableValue[weekday].allDay = false
      delete this.mutableValue[weekday].from
      delete this.mutableValue[weekday].to

      this.emitValue()
    },
    onChangeAllDayState(weekday) {
      if (!this.mutableValue[weekday].allDay) {
        this.setDefaultTime(weekday)
        return
      }

      delete this.mutableValue[weekday].from
      delete this.mutableValue[weekday].to
      this.emitValue()
    },
    setDefaultTime(weekday) {
      if (!this.mutableValue[weekday].from) {
        this.mutableValue[weekday].from = DEFAULT_FROM_HOUR
      }

      if (!this.mutableValue[weekday].to) {
        this.mutableValue[weekday].to = DEFAULT_TO_HOUR
      }

      this.emitValue()
    },
    validateBusinessHours() {
      const bothBusinessHoursPresent = (from, to) => (from && to)
      const correctBusinessHours = (from, to) => new Date(`1970-01-01T${from}`) < new Date(`1970-01-01T${to}`)

      this.weekdays.forEach((weekday) => {
        const {
          isOpen, allDay, from, to
        } = this.mutableValue[weekday]

        if (!isOpen) return
        if (allDay) return

        const hoursPresent = bothBusinessHoursPresent(from, to)

        if (!hoursPresent) {
          this.errors[weekday] = ['Both times must be present']
          return
        }

        if (correctBusinessHours(from, to)) return

        this.errors[weekday] = ['Opening time must be earlier than closing time']
      })
    },
    capitalize
  }
}
</script>

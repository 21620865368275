<template>
  <form class="review-reply-form" @submit.prevent="sendReply">
    <div class="review-reply-form__input">
      <div class="review-reply-form__line">
        <textarea
          v-model="replyText"
          class="review-reply-form__textarea form-control"
          :class="{ 'border-danger': errorText, processing: isGenerating }"
          :disabled="isGenerating"
          :readonly="isGenerating"
          placeholder="Write an answer or generate it" />
      </div>
      <small v-if="errorText" class="text-danger">{{ errorText }}</small>
      <small v-if="successText" class="color-green">{{ successText }}</small>
    </div>
    <div class="review-reply-form__bottom">
      <button class="btn btn-primary" :disabled="!replyText || isEditingDisabled">
        Publish
      </button>
      <div v-if="!hasOpenaiKey || (hasOpenaiKey && !disabledOpenaiKey)">
        To enable automatic AI-generated responses, add OpenAI key in <a href="/integrations" target="_blank">organization settings</a>.
      </div>
      <div v-if="hasOpenaiKey && disabledOpenaiKey">
        <i id="openai_generate_reply_review_tooltip" class="fa-solid fa-circle-exclamation text-primary" />
        <b-tooltip target="openai_generate_reply_review_tooltip" title="Easily craft thoughtful and professional responses
        to customer reviews with just a click." />
      </div>
      <div class="review-reply-form__ai">
        <button
          type="button"
          :class="['btn-ai', { processing: isGenerating }]"
          :disabled="isGenerating || !hasOpenaiKey || (hasOpenaiKey && !disabledOpenaiKey) || isEditingDisabled"
          @click="generateReviewReply">
          <template v-if="isGenerating">
            <i class="fa-solid fa-spinner-scale fa-spin-pulse" />
            Generating...
          </template>
          <template v-else>
            <i class="far fa-sparkles" />
            {{ aiButtonText }}
          </template>
        </button>
      </div>
      <button
        type="button"
        class="btn btn-border"
        @click="$emit('close')">
        Close
      </button>
    </div>
  </form>
</template>

<script>
import axiosTransform from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    reviewId: {
      type: String,
      required: true
    },
    aiMessages: {
      type: Array,
      required: true
    },
    autoGeneratedReply: {
      type: String,
      required: false
    },
    openaiDisable: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      replyText: this.autoGeneratedReply || '',
      memReplyText: '',
      errorText: '',
      successText: '',
      isGenerating: false
    }
  },
  created() {
    this.hasOpenaiKey = Styxie.USER.hasOpenaiKey
    this.isEditingDisabled = !Styxie?.USER?.editor
  },
  methods: {
    sendReply() {
      this.$emit('sendReply', this.replyText)
    },
    async generateReviewReply() {
      this.isGenerating = true
      this.successText = ''
      this.errorText = ''
      this.memReplyText = this.replyText
      this.replyText = 'Generating reply...'

      axios.post(
        '/api/reviews_dashboard/generate_reply',
        {
          reviewId: this.reviewId
        },
        axiosTransform
      ).then().catch(() => {
        this.replyText = this.memReplyText
        toastr.error(DEFAULT_ERROR_MESSAGE)
        this.isGenerating = false
      })
    },
    handleAIMessage() {
      const relatedAiMessage = this.aiMessages.find((msg) => msg.reviewId === this.reviewId)
      if (!relatedAiMessage) return

      this.isGenerating = false
      this.errorText = ''
      this.successText = ''

      const { result, failed } = relatedAiMessage

      if (failed) {
        this.replyText = this.memReplyText
        this.errorText = result
        toastr.error(this.errorText)
        return
      }

      this.replyText = result
      this.successText = 'Review reply has been generated.'
      toastr.success(this.successText)
      this.$emit('removeAiMessage')
    }
  },
  computed: {
    tooltipId() {
      return `reply-form-popover-${this.reviewId}`
    },
    aiButtonText() {
      if (this.successText || this.autoGeneratedReply) {
        return 'Regenerate reply'
      }
      return 'Generate reply'
    },
    disabledOpenaiKey() {
      return this.openaiDisable ? !this.openaiDisable : Styxie?.USER?.isOpenaiKeyValid
    }
  },
  watch: {
    templateList: {
      handler() {
        this.saveTemplates()
      },
      deep: true
    },
    aiMessages() {
      this.handleAIMessage()
    }
  }
}
</script>

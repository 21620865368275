<template>
  <Multiselect
    :value="value"
    :options="options"
    :track-by="optionData.trackBy"
    :label="optionData.label"
    :searchable="optionData.searchable"
    :internal-search="optionData.internalSearch"
    :show-labels="optionData.showLabels"
    :allow-empty="optionData.allowEmpty"
    :max-height="optionData.maxHeight"
    :multiple="optionData.multiple"
    :placeholder="optionData.placeholder"
    @search-change="searchChange"
    @input="onInput"
    @select="onSelect"
    @remove="onRemove">
    <template slot="singleLabel" slot-scope="{ option }">
      <span :title="multiselectCaption(option, optionData)">
        {{ multiselectCaption(option, optionData) }}
      </span>
    </template>
    <template slot="selection" slot-scope="{ values, isOpen }">
      <slot name="selection">
        <span v-if="values.length && !isOpen"
          class="multiselect__single"
          :title="values.length === 1 ? multiselectCaption(values, optionData) : ''">
          {{ values.length === 1 ? multiselectCaption(values, optionData) : `${selectedOptions.length || values.length} options selected` }}
        </span>
      </slot>
    </template>
    <template slot="option" slot-scope="{ option }">
      <div :class="optionDescClasses(optionData)">
        <span class="option__title">
          {{ multiselectCaption(option, optionData) }}
        </span>
        <br>
        <small v-if="option.locationType === 'sab'" class="d-block option__small">Service Area Business</small>
        <small v-else-if="option.address" class="d-block option__small">{{ option.address }} </small>
        <small v-if="option.storeCode" class="d-block option__small">{{ option.storeCode }} </small>
      </div>
    </template>
    <template slot="afterList">
      <div v-observe-visibility="{
        callback: reachedEndOfList,
        throttle: 500
      }" />
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)

export default {
  components: { Multiselect },
  props: {
    optionData: { type: Object, required: true },
    value: { type: [Object, String, Number, Array], default: null },
    selectedOptions: { type: Array, default: () => []}
  },
  data() {
    return {
      selectValue: null,
      options: [],
      page: 1,
      search: ''
    }
  },
  created() {
    this.options = this.optionData.options

    if (this.optionData.sourceOptions) {
      this.optionData.sourceOptions().then((res) => {
        this.options = [...this.options, ...res.data]
      })
    }
  },
  methods: {
    multiselectCaption(option, { label, alternateLabel }) {
      const value = Array.isArray(option) ? option[0] : option
      return label || alternateLabel ? (value[label] || value[alternateLabel]) : value
    },
    optionDescClasses({ optionClasses }) {
      return `option__desc ${optionClasses || 'text-truncate'}`
    },
    searchChange(search) {
      this.search = search
      this.page = 1
      if (this.optionData.sourceOptions) {
        this.optionData.sourceOptions({ search, page: 1 }).then((res) => {
          this.options = res.data
        })
      }
    },
    reachedEndOfList(isVisible) {
      if (!isVisible) return

      this.page++
      if (this.optionData.sourceOptions) {
        this.optionData.sourceOptions({ search: this.search, page: this.page }).then((res) => {
          this.options = [...this.options, ...res.data]
        })
      }
    },
    onInput($event) {
      this.$emit('onInput', $event)
      this.$emit('input', $event)
    },
    onSelect($event) {
      this.$emit('select', $event)
    },
    onRemove($event) {
      this.$emit('remove', $event)
    }
  },
  watch: {
    'optionData.options': {
      handler(newVal) {
        this.options = newVal
      },
      deep: true
    }
  }
}
</script>

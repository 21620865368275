<template>
  <div class="compact-tabs" :class="{ disabled }">
    <div class="compact-tabs__list">
      <div
        v-for="({ text, icon }, tabName) in tabList"
        :key="tabName"
        class="compact-tabs__item"
        :class="{ active: tabName === activeTab }"
        @click="$emit('setActiveTab', tabName)">
        <i v-if="icon" :class="icon" />
        {{ text }}
      </div>
    </div>
    <div class="compact-tabs__content">
      <slot :name="activeTab" />
      <slot name="placeholder" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Object,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import JobsIndex from '../../../vue_widgets/mobile/service_titan/jobs/index'

Styxie.Initializers.MobileServiceTitanJobs = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-service-titan-jobs-index'),
        render: (h) => h(JobsIndex, { props: camelCaseKeys(params) })
      })
    })
  },
  show: (params) => {
    $(() => {
      $('.js-service-titan-create-checkin').on('click', () => {
        $.ajax2({
          method: 'POST',
          url: params.createCheckinUrl,
          success: () => {
            toastr.success('Processing...')
          },
          error: (res) => {
            const message = (res.responseJSON && res.responseJSON.error) || 'Something went wrong! Please try again later.'
            toastr.warning(message)
          }
        })
      })
    })
  }
}

Styxie.Initializers.Global = {
  index: (data) => {
    const {
      beginningOfWeek,
      hasOpenaiKey,
      isOpenaiKeyValid,
      editor,
      ...wlc
    } = data
    wlc.gbName = data.appCodename === 'linda' ? 'Check Ins' : 'GeoBooster'

    Styxie.WLC = wlc
    Styxie.USER = {
      beginningOfWeek,
      hasOpenaiKey,
      isOpenaiKeyValid,
      editor
    }
  }
}

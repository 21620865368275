<template>
  <div class="form-field form-field--rem form-field--grid">
    <span class="m-0">{{ label }}</span>
    <Multiselect
      :class="{'multiselect--min-height': contentMinHeight}"
      :value="val"
      :options="mutableValues"
      :allow-empty="allowEmpty"
      :close-on-select="!multiple"
      :show-labels="true"
      :preselect-first="preselectFirst"
      :open-direction="openDirection"
      :disabled="disabled"
      :multiple="multiple"
      :placeholder="placeholder"
      :label="labelSelect"
      :track-by="trackBy"
      :max-height="400"
      :internal-search="false"
      @input="handleInput"
      @select="handleSelect"
      @search-change="handleSearch">
      <template slot="selection" slot-scope="props">
        <slot name="selection" :props="props">
          <span class="multiselect__single" v-if="props.values.length && !props.isOpen">
            {{ props.values.length }} item{{ props.values.length !== 1 ? 's' : '' }} selected
          </span>
        </slot>
      </template>
      <template #singleLabel="{ option }">
        <i v-if="option.icon" :class="option.icon" class="pr-2" />
        <span>{{ option.text }}</span>
      </template>
      <template #option="{ option }">
        <div class="option__desc text-truncate">
          <i v-if="option.icon" :class="option.icon" class="pr-2" />
          <span class="option__title">
            {{ option.text }}
          </span>
          <template v-if="option.description && option.description.length">
            <small v-for="(item, index) in option.description" :key="index" class="d-block text-muted text-normal text-break">{{ item }}</small>
          </template>
        </div>
      </template>
      <template v-if="noTags" #tag>{{ '' }}</template>
    </Multiselect>
    <div v-if="error" class="text-danger">{{ errorText }}</div>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      type: [String, Number, Array, Object],
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    native: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false
    },
    labelSelect: { type: String, default: 'text' },
    trackBy: { type: String, default: 'value' },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    errorText: {
      type: String,
      required: false
    },
    type: {
      required: false,
      default: 'text'
    },
    disabled: {
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select option'
    },
    preselectFirst: {
      type: Boolean,
      required: false,
      default: false
    },
    openDirection: {
      type: String,
      required: false
    },
    contentMinHeight: {
      type: Boolean,
      required: false,
      default: false
    },
    noTags: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      mutableValues: this.values
    }
  },
  methods: {
    handleInput(newValue) {
      if (!newValue) {
        this.$emit('input', '')
        return
      }

      const valueToEmit = this.multiple ? newValue.map((selectedOption) => selectedOption[this.trackBy]) : newValue[this.trackBy]

      this.$emit('input', valueToEmit ?? '')
    },
    handleSelect(e) {
      this.$emit('select', e)
    },
    handleSearch(search) {
      this.mutableValues = this.values.filter((option) => {
        let searchString = option[this.labelSelect]

        if (option.description) {
          searchString += ` ${option.description.join(' ')}`
        }

        return searchString.toLowerCase().includes(search.trim().toLowerCase())
      })
    }
  },
  computed: {
    val() {
      if (this.multiple) {
        return this.values.filter((elm) => this.value.includes(elm[this.trackBy]))
      }

      const value = this.values.find((elm) => elm[this.trackBy] === this.value)

      if (!value && !this.allowEmpty) {
        return this.values[0]
      }

      return value
    }
  },
  watch: {
    values: {
      handler(newValues) {
        this.mutableValues = newValues
      },
      deep: true
    }
  }
}
</script>

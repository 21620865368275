import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import IncomingCallsIndex from 'vue_widgets/jensen/incoming_calls/index.vue'
import IncomingCallsShow from 'vue_widgets/jensen/incoming_calls/show.vue'

Styxie.Initializers.JensenIncomingCalls = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-incoming-calls-index'),
        render: (h) => h(IncomingCallsIndex, { props: camelCaseKeys(params) })
      })
    })
  },
  show: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-incoming-calls-show'),
        render: (h) => h(IncomingCallsShow, { props: camelCaseKeys(params) })
      })
    })
  }
}

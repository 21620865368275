<template>
  <div class="mb-5">
    <h2 class="h3 m-0 mb-2 font-weight-semi-bold text-dark">Moment destinations</h2>
    <div v-for="(toggle, toggleKey) in shownToggles"
      :key="toggleKey"
      class="form-field mb-3 switcher-container">
      <label class="switcher">
        <input type="checkbox" v-model="toggle.value">
        <span class="caption">
          {{ toggle.text }}
        </span>
        <i class="toogle-switch" />
      </label>
    </div>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import axiosTransform from 'common/axios'

export default {
  props: {
    mobileBusinessPath: {
      type: String,
      required: true
    },
    publishFacebook: {
      type: Boolean,
      required: false,
      default: false
    },
    publishGmb: {
      type: Boolean,
      required: false,
      default: false
    },
    publishInstagram: {
      type: Boolean,
      required: false,
      default: false
    },
    publishWebhook: {
      type: Boolean,
      required: false,
      default: false
    },
    fbEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    igEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      toggles: {
        publishGmb: {
          value: this.publishGmb,
          text: 'Publish to GBP'
        },
        publishWebhook: {
          value: this.publishWebhook,
          text: 'Publish via Webhook'
        },
        publishFacebook: {
          value: this.fbEnabled && this.publishFacebook,
          text: 'Publish to Facebook'
        },
        publishInstagram: {
          value: this.igEnabled && this.publishInstagram,
          text: 'Publish to Instagram'
        }
      }
    }
  },
  methods: {
    update() {
      const mobileBusiness = {}
      Object.keys(this.toggles).forEach((key) => {
        mobileBusiness[key] = this.toggles[key].value
      })

      axios.patch(this.mobileBusinessPath, { mobileBusiness }, axiosTransform)
        .then(() => {
          toastr.success('Setting updated successfully')
        })
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  },
  computed: {
    shownToggles() {
      const { publishInstagram, publishFacebook, ...restToggles } = this.toggles
      const toggles = { ...restToggles }

      if (this.fbEnabled) {
        toggles.publishFacebook = publishFacebook
      }

      if (this.igEnabled) {
        toggles.publishInstagram = publishInstagram
      }

      return toggles
    }
  },
  watch: {
    toggles: {
      handler() {
        this.update()
      },
      deep: true
    }
  }
}
</script>

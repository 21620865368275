import { omit } from 'common/helpers'

// updateErrors should receive errors in the following format:
//
//   {
//     "ring_through_number": [
//       "can't be blank"
//     ]
//   }
export default {
  data() {
    return {
      errors: {}
    }
  },
  methods: {
    updateErrors(newErrors) {
      this.errors = newErrors
    },
    errorText(field) {
      if (!this.errors) {
        return null
      }

      const fieldErrors = this.errors[field]

      if (!fieldErrors || fieldErrors.length === 0) {
        return null
      }

      return fieldErrors.join(', ')
    },
    hasError(field) {
      return Boolean(this.errorText(field))
    },
    cleanError(...fields) {
      if (!this.errors) {
        return
      }

      this.errors = omit(this.errors, fields)
    }
  }
}

<template>
  <table v-if="parsedAddress" class="mt-2">
    <template v-for="(value, key) in parsedAddress">
      <tr v-if="value">
        <td class="text-muted">{{ addressKeyTitle[key] }}:</td>
        <td class="font-weight-semi-bold pl-1">{{ value }}</td>
      </tr>
    </template>
  </table>
</template>

<script>
import camelcaseKeys from 'camelcase-keys-deep'

import { ADDRESS_SOURCES } from '../helpers'

export default {
  props: {
    addressSource: { type: String, required: true, validator: (source) => Object.keys(ADDRESS_SOURCES).includes(source) },
    addressComponents: { type: [Array, Object], default: () => ([]) },
    checkin: { type: Object, default: () => ({}) },
    isAddressEdited: { type: Boolean, default: false },
    fromOffice: { type: Boolean, default: false }
  },
  created() {
    this.addressKeyTitle = {
      locality: 'City',
      administrativeAreaLevel1: 'State',
      country: 'Country'
    }
    this.addressTypes = ['locality', 'administrative_area_level_1', 'country']
  },
  computed: {
    parsedAddress() {
      if (this.addressSource === ADDRESS_SOURCES.FROM_ST_JOB) {
        return {
          locality: this.checkin.serviceTitanJob.parsedAddress?.city,
          administrativeAreaLevel1: this.checkin.serviceTitanJob.parsedAddress?.administrativeArea?.name,
          country: this.checkin.serviceTitanJob.parsedAddress?.country?.name
        }
      }

      if (this.addressSource === ADDRESS_SOURCES.GOOGLE_AUTOCOMPLETE) {
        if (!this.addressComponents?.length) {
          return null
        }

        const address = {}
        this.addressTypes.forEach((addressType) => {
          address[addressType] = this.addressComponents.find((component) => component.types[0] === addressType)?.longName
        })

        return camelcaseKeys(address)
      }

      if (this.addressSource === ADDRESS_SOURCES.PUBLIC_API_ADDRESS) {
        return {
          locality: this.checkin.parsedAddress?.city,
          administrativeAreaLevel1: this.checkin.parsedAddress?.administrativeArea?.name,
          country: this.checkin.parsedAddress?.country?.name
        }
      }

      return null
    }
  }
}
</script>

<template>
  <div>
    <AddGooglePlace ref="addGooglePlace" v-if='createGpAllowed' :maps-key="mapsKey" @reload-table="reloadTable" />
    <div v-if="hasLocations" class="container">
      <LocationFilter ref="locationFilter"
        :by-state-options="stateOptions"
        :by-tag-options="updatedTagOptions"
        :by-sub-account-options="subAccountOptions"
        :by-account-options="accountOptions"
        :gp-tutorial-link="gpTutorialLink"
        :initial-filters="filters"
        @applyFilter="applyFilter" />
      <div v-if="gpTutorialLink" class="text-right">
        <a :href="gpTutorialLink">
          <i class="far fa-question-square color-warning" aria-hidden="true" />
          How to add locations not connected to your GBP?
        </a>
      </div>
      <TableActions
        v-if="partSelected.selectedCount !== 0"
        :part-selected="partSelected"
        :checked="checkAll"
        :locations="locations"
        :tag-options="updatedTagOptions"
        :rows-total-count="rowsTotalCount"
        :filters="filters"
        @enableChecked="setEnableChecked(true)"
        @disableChecked="setEnableChecked(false)"
        @onChecked="checkLocations"
        @saveTags="saveTags"
        @saveRollbackSettings="saveRollbackSettings"
        @saveNotificationSettings="saveNotificationSettings"
        @bulkPost="bulkPost" />
      <VueSlimTable
        ref="table"
        class="responsive-table locations-table"
        :source="tableSource"
        :columns="showColumnsHeader"
        :per-page="perPage">
        <template #head:name="">
          <Checkbox
            :value="checkAll"
            :part-selected="partSelected.isPartSelected"
            @click.native.prevent.stop="checkLocations(!checkAll)">
            Locations
          </Checkbox>
        </template>
        <template #row="{ row }">
          <TableRow
            :row="row"
            :key="row.id"
            :gmb-locations-path="gmbLocationsPath"
            :org-locations-path="orgLocationsPath"
            :api-org-locations-path="apiOrgLocationsPath"
            :selected-tags="filters.byTag"
            @onRowCheckToggle="onRowCheckToggle"
            @onLocationToggle="onLocationToggle"
            @addTagToFilter="addTagToFilter" />
        </template>
      </VueSlimTable>
      <button v-if="hasAdminPrivileges" type="button" class="btn btn-primary my-5 rounded" @click.prevent="$refs.addGooglePlace.toggleSlidePannel(false)">
        Add new location
      </button>
    </div>
    <div v-else class="container">
      <div class='panel panel-default mt-3'>
        <div class='panel-body'>
          <div class='empty-state text-center mt-6 mb-6'>
            <div class="row">
              <div class="col-8 col-md-1 mx-auto">
                <img src="~/no_data/google-accounts.svg">
              </div>
            </div>
            <div class='text-muted'>
              <h3 class='mt-4 mb-4'>You don't have any locations yet.</h3>
              <template v-if="hasAdminPrivileges">
                <p>
                  <span class='bold'>Hey!</span>
                  It looks like you're just starting out.
                  Please <a :href="newGoogleAccountPath" class="alert-info p-1">connect a Google account</a>
                  or <a @click.prevent="$refs.addGooglePlace.toggleSlidePannel(false)" href="#" class="alert-info p-1">manually add a location</a>
                  not connected to your GBP.
                </p>
                <a :href='gpTutorialLink'>
                  <i class="far fa-question-square color-warning" aria-hidden="true" />
                  How to add locations not connected to your GBP?
                </a>
              </template>
              <p v-else>
                Only owner or admin accounts can add Google places or connect Google accounts.
                Request access to the desired location from the user who invited you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys-deep'
import { DEFAULT_ERROR_MESSAGE, LOCATION_FILTER_KEY } from 'common/constants'
import axiosTransform from 'common/axios'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import LocationFilter from './index/filter'
import TableActions from './index/table_actions'
import TableRow from './index/table_row'
import Checkbox from '../../components/checkbox'
import AddGooglePlace from './index/add_google_place'

export default {
  components: {
    LocationFilter,
    TableRow,
    TableActions,
    AddGooglePlace,
    Checkbox
  },
  props: {
    accountOptions: Array,
    subAccountOptions: Array,
    tagOptions: Array,
    stateOptions: Array,
    mapsKey: String,
    orgLocationsPath: { type: String, default: '' },
    apiOrgLocationsPath: { type: String, default: '' },
    gmbLocationsPath: { type: String, default: '' },
    gpTutorialLink: { type: String, default: '' },
    createGpAllowed: { type: Boolean, default: false },
    hasLocations: { type: Boolean, default: false },
    hasAdminPrivileges: { type: Boolean, default: false },
    newGoogleAccountPath: { type: String, default: '' }
  },
  computed: {
    selectedRowIds() {
      return this.checkAll ? 'all' : this.$refs.table.rows.filter((row) => row.checked).map((el) => el.id)
    },
    showColumnsHeader() {
      return this.partSelected.selectedCount > 0 ? [] : this.columns
    },
    partSelected() {
      const selectedCount = this.checkAll ? this.rowsTotalCount : this.locations.filter((row) => row.checked).length
      return {
        selectedCount: selectedCount,
        length: this.rowsTotalCount,
        isPartSelected: selectedCount !== 0 && selectedCount !== this.rowsTotalCount,
        caption: `Selected ${selectedCount} ${this.$options.filters.pluralize('location', selectedCount)} of ${this.rowsTotalCount}`,
        captionAll: `Selected ${selectedCount} ${this.$options.filters.pluralize('location', selectedCount)}`
      }
    },
    updatedTagOptions() {
      return Array.from(new Set([
        ...this.tagOptions,
        ...this.realtimeAddedTags.map((tag) => ({ label: tag, value: tag }))
      ]))
    }
  },
  data() {
    return {
      filters: {},
      locations: [],
      checkAll: false,
      rowsTotalCount: 0,
      realtimeAddedTags: []
    }
  },
  methods: {
    tableSource(params) {
      return axios.get(
        this.apiOrgLocationsPath,
        {
          params: { ...params, ...snakeCaseKeys(this.filters) },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => {
        this.locations = res.data.rows.map((row) => ({ ...row, checked: this.checkAll }))
        this.rowsTotalCount = res.data.total_count

        return this.locations
      })
    },
    reloadTable() {
      this.$refs.table.reload()
    },
    applyFilter(filters) {
      window.localStorage.setItem(`${LOCATION_FILTER_KEY}_${this.userEmail}`, JSON.stringify(filters))
      this.filters = filters
      if (!this.$refs.table) return
      this.$nextTick(this.$refs.table.refetch)
    },
    setEnableChecked(enabled) {
      this.toggleChecked(enabled)
        .then((res) => {
          if (!(res instanceof Error)) toastr.success(`Locations were ${enabled ? 'enabled' : 'disabled'}`)
          if (this.filters.disabledState === '') return
          if (!this.$refs.table) return
          this.$nextTick(this.$refs.table.refetch)
        })
    },
    toggleChecked(bool) {
      const checked = this.$refs.table.rows.filter((row) => row.checked)
      const oids = this.checkAll ? 'all' : checked.map((el) => el.id)
      return axios.post(`${this.apiOrgLocationsPath}/${bool ? 'en' : 'dis'}able`, {
        oids,
        ...this.filters
      }, axiosTransform)
        .then(() => {
          checked.forEach((el) => {
            el.enabled = bool
          })

          return checked
        })
        .catch((err) => {
          if (err?.response?.status !== 403) {
            const message = err?.response?.data?.text || DEFAULT_ERROR_MESSAGE
            toastr.error(message)
          }

          return err
        })
    },
    onRowCheckToggle({ locationId, bool }) {
      this.locations.find((row) => row.id === locationId).checked = bool
      this.checkAll = this.areLocationsChecked()
    },
    onLocationToggle({ locationId, bool }) {
      this.locations.find((row) => row.id === locationId).enabled = bool
    },
    areLocationsChecked() {
      return !this.locations.find((row) => !row.checked) && this.rowsTotalCount <= this.perPage
    },
    checkLocations(value) {
      this.checkAll = value
      this.$refs.table.rows.forEach((row) => {
        row.checked = value
      })
    },
    saveTags(tags) {
      axios.post(`${this.apiOrgLocationsPath}/add_tags`, {
        tags,
        oids: this.selectedRowIds,
        ...this.filters
      }, axiosTransform)
        .then(() => {
          toastr.success('Locations tags updated')
          const newTags = tags.filter((tag) => !this.updatedTagOptions.find(({ value }) => value === tag))
          this.realtimeAddedTags = [...this.realtimeAddedTags, ...newTags]
          this.reloadTable()
        })
    },
    saveRollbackSettings(settings) {
      const rollbacks = settings.reduce((memo, { attr, data }) => {
        memo[attr] = data.locked

        return memo
      }, {})
      // Important: this route does not exist
      axios.post('/api/gmb/locations/bulk_actions/rollbacks', {
        rollbacks,
        oids: this.selectedRowIds,
        ...this.filters
      }, axiosTransform)
        .then(() => toastr.success('Locations rollbacks settings will be updated soon'))
    },
    saveNotificationSettings(settings) {
      const notifications = settings.reduce((memo, { attr, data }) => {
        memo[`${attr}_by_sms`] = data.sms
        memo[`${attr}_by_email`] = data.email

        return memo
      }, {})
      axios.patch('/api/organizations_locations/update_notifications_settings', {
        notifications,
        oids: this.selectedRowIds,
        ...this.filters
      }, axiosTransform)
        .then(() => toastr.success('Locations notifications settings have been updated'))
    },
    bulkPost(href) {
      const queryParams = qs.stringify(
        { oids: this.selectedRowIds, ...snakeCaseKeys(this.filters) },
        { arrayFormat: 'brackets' }
      )
      Turbolinks.visit(`${href}?${queryParams}`)
    },
    addTagToFilter(tag) {
      if (this.$refs.locationFilter.byTag) {
        const tagIndex = this.$refs.locationFilter.byTag.findIndex((tagListItem) => tagListItem.value === tag)
        if (tagIndex === -1) {
          this.$refs.locationFilter.byTag.push({ label: tag, value: tag })
        } else {
          this.$refs.locationFilter.byTag.splice(tagIndex, 1)
        }
      } else {
        this.$refs.locationFilter.byTag = [{ label: tag, value: tag }]
      }
      this.applyFilter({
        ...this.filters,
        byTag: this.$refs.locationFilter.byTag.map(({ value }) => value)
      })
    }
  },
  created() {
    this.userEmail = Cookies.get('user_email')
    this.perPage = 50

    this.columns = [
      { title: 'Name', key: 'name', orderable: true },
      { title: 'Address', key: 'address' },
      { title: 'Keywords', key: 'keywords_synced_at' },
      { title: 'Accounts', key: 'google_accounts' }
    ]

    // this.filters = extractSessionStorage(`${LOCATION_FILTER_KEY}_${this.userEmail}`) || {}
    this.filters = JSON.parse(window.localStorage.getItem(`${LOCATION_FILTER_KEY}_${this.userEmail}`)) || {}

    this.channel = subscribe('GooglePlacesChannel', (msg) => {
      const {
        kind, success, name, count
      } = camelCaseKeys(msg)
      if (kind === 'place:create') {
        if (success) {
          this.reloadTable()
          toastr.success(`Location ${name} successfully added.`)
        } else {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }
      } else if (kind === 'place:bulk:create') {
        if (success) {
          this.reloadTable()
          toastr.success(`${count} Location${count === 1 ? ' was' : 's were'} successfully added.`)
        } else {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }
      }
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  }
}
</script>

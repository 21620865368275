<template>
  <div class="geobooster-widgets-edit-form">
    <div v-for="(line, index) in settingsStepLayout"
      class="geobooster-widgets-edit-form__line"
      :key="`settings-line-${index}`"
      :class="{ 'flex-xlg-row': line.length > 1 }">
      <Component
        v-for="field in line"
        v-model="field.value"
        v-bind="field.additionalAttrs ?? {}"
        :key="field.name"
        :is="field.component"
        :values="field.values"
        :error="field.error"
        :error-text="field.errorText"
        :type="field.type ? field.type : 'text'"
        :label="field.label"
        :name="field.name"
        @input="field.error = false"
        @blur="$emit('triggerValidate')" />
    </div>
  </div>
</template>

<script>
import InfoPanel from '../../../components/info_panel'
import CustomInput from '../../../components/custom_input'
import CustomCheckbox from '../../../components/checkbox'
import CustomSelect from '../../../components/custom_select'
import Radio from '../../../components/radio_panels'

export default {
  components: {
    InfoPanel,
    CustomInput,
    CustomSelect,
    CustomCheckbox,
    Radio
  },
  props: {
    fields: {
      type: Object,
      required: true
    },
    sliderViewIsSelected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    settingsStepLayout() {
      const layout = []

      layout.push([this.fields.name])
      layout.push([this.fields.googleApiKey])

      if (this.fields.googleApiKeyInfo.show) {
        layout.push([this.fields.googleApiKeyInfo])
      }

      layout.push([this.fields.googleMapId])
      layout.push([this.fields.mapHeight, this.fields.zoom])
      layout.push([this.fields.showCustomer])
      layout.push([this.fields.showAddressDisclamer])
      layout.push([this.fields.showDate])
      layout.push([this.fields.hideReviewAuthor])

      if (this.fields.hideReviewAuthor.value === 'custom') {
        layout.push([this.fields.reviewAuthorStub])
      }

      if (!this.sliderViewIsSelected) {
        layout.push([this.fields.momentsPerPage])
      }

      return layout
    }
  }
}
</script>

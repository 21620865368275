<template>
  <div class="theme-preset-select">
    <RadioPanels
      class="theme-preset-select__line"
      :value="value.themeType"
      label="Theme type"
      name="themeType"
      :values="values"
      @input="changeTheme({ themeType: $event })" />
    <div class="theme-preset-select__line">
      <div class="theme-preset-select__controls">
        <button v-if="prevThemeExists"
          class="theme-preset-select__button theme-preset-select__button--prev"
          @click="changeTheme({selectedThemeIndex: value.selectedThemeIndex - 1})">
          <i class="fa-regular fa-chevron-left" />
        </button>
        <span>{{ selectedThemeName }}</span>
        <button
          v-if="nextThemeExists"
          class="theme-preset-select__button theme-preset-select__button--next"
          @click="changeTheme({selectedThemeIndex: value.selectedThemeIndex + 1})">
          <i class="fa-regular fa-chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioPanels from 'vue_widgets/components/radio_panels.vue'
import themePresetsMixin from '../mixins/themePresetsMixin'

export default {
  components: {
    RadioPanels
  },
  mixins: [themePresetsMixin],
  props: {
    cardBackgroundType: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    values: {
      type: Array,
      required: true
    }
  },
  computed: {
    themesList() {
      return this.themes[this.cardBackgroundType][this.value.themeType]
    },
    activeTheme() {
      return this.themesList[this.value.selectedThemeIndex]
    },
    selectedThemeName() {
      return this.activeTheme.themeName
    },
    prevThemeExists() {
      return this.value.selectedThemeIndex > 0
    },
    nextThemeExists() {
      return this.value.selectedThemeIndex < this.themesList.length - 1
    }
  },
  methods: {
    changeTheme({
      themeType = this.value.themeType,
      selectedThemeIndex = this.value.selectedThemeIndex
    }) {
      this.$emit('changeTheme', { themeType, selectedThemeIndex })
    }
  }
}
</script>

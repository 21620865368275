<template>
  <div class="mv-checkin-item">
    <div class="mv-checkin-item-title">
      <img :src="require(`mv/moment_state_icons/checkin_st_job.svg`)">
      {{ stJob.formattedAddress }}

      <a v-if="stJob.mobileCheckinId"
        class="btn btn-link"
        :href="`/gb/moments/${stJob.mobileCheckinId}/edit`">
        <i class="far fa-fw fa-pencil" />
      </a>

      <button type="button"
        v-else
        :disabled="stJobGeneratingId"
        @click.prevent="convertJob2Moment"
        class="mv-checkin-item-create-btn btn btn-xs btn-outline-primary rounded">
        <template v-if="stJobGeneratingId === stJob.id">
          <i class="far fa-fw fa-spinner fa-spin mr-2" />Creating...
        </template>
        <template v-else>
          Create&nbsp;Moment
        </template>
      </button>
    </div>

    <div class="mv-checkin-item-wrapper">
      <div class="mv-checkin-item-info">
        <p class="text-break">{{ stJob.description }}</p>
        <div class="d-flex mb-2">
          <span class="info-key">Category</span>
          <span class="info-value">{{ stJob.category || '-' }}</span>
        </div>
        <div class="d-flex mb-2">
          <span class="info-key">Customer</span>
          <span class="info-value">{{ stJob.customer ?? '-' }}</span>
        </div>
        <div class="d-flex mb-2">
          <span class="info-key">Completed date</span>
          <span class="info-value font-weight-semi-bold text-capitalize">
            {{ formatISODatetime(stJob.remoteCompletedAt) }}
          </span>
        </div>

        <div class="pt-2 " />
      </div>
      <div v-if="mediaData.length" class="mv-checkin-item-images">
        <template v-if="mediaData.length <= 4">
          <template v-for="({ url }, index) in mediaData">
            <img
              :key="index"
              :src="url"
              alt=""
              @click="openPreview(mediaData, index)">
          </template>
        </template>
        <template v-else>
          <template v-for="({ url }, index) in mediaData.slice(0, 3)">
            <img
              :key="index"
              :src="url"
              alt=""
              @click="openPreview(mediaData, index)">
          </template>
        </template>
        <div v-if="mediaData.length > 4" class="mv-checkin-item-images-more" @click="openPreview(mediaData, 3)">
          +{{ mediaData.length - 3 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatISODatetime from 'helpers/formatters'

export default {
  props: {
    stJob: { type: Object, required: true },
    stJobGeneratingId: { type: Number }
  },
  computed: {
    mediaData() {
      return this.stJob.attachments.filter((media) => media.url)
    }
  },
  methods: {
    formatISODatetime,
    openPreview(media, index) {
      this.$emit('openPreview', {
        mediaSet: media,
        mediaIndex: index
      })
    },
    convertJob2Moment() {
      this.$emit('convertJob2Moment', this.stJob.id)
    }
  }
}
</script>

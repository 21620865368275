<template>
  <div
    class="incoming-calls-chart"
    id="incoming-calls-chart" />
</template>

<script>
import Highcharts from 'highcharts'
import qs from 'qs'
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import camelcase from 'camelcase'

import { daysAgo } from 'common/helpers'

// some black magic over here
// https://www.highcharts.com/forum/viewtopic.php?t=50016
// https://github.com/highcharts/highcharts/issues/5028
// https://jsfiddle.net/BlackLabel/adskoqnp/
Highcharts.wrap(Highcharts.Axis.prototype, 'getClosest', function wrapFunc() {
  const xValues = this.series.reduce((prev, series) => prev.concat(series.processedXData), []).sort((a, b) => a - b)

  let i = xValues.length || 1
  let distance
  let closestPointRange
  while (--i) {
    distance = this.isLog ?
      this.val2lin(xValues[i]) - this.val2lin(xValues[i - 1]) :
      xValues[i] - xValues[i - 1]

    if (distance > 0 && (closestPointRange === undefined || distance < closestPointRange)) {
      closestPointRange = distance
    }
  }
  return closestPointRange
})

export default {
  props: {
    filters: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data: () => ({
    chartData: null,
    chart: null
  }),
  methods: {
    async fetchChartData() {
      let response = null

      try {
        response = await axios.get(
          '/call_tracking/ajax/incoming_calls/chart_data',
          {
            params: this.filters,
            paramsSerializer(json) {
              return qs.stringify(snakecaseKeys(json), { arrayFormat: 'brackets' })
            }
          }
        )
      } catch (error) {
        toastr.error(error)
      }

      const camelcaseKeys = (obj) => {
        const camelcased = {}

        Object.keys(obj).forEach(((key) => {
          camelcased[camelcase(key)] = obj[key]
        }))

        return camelcased
      }

      this.chartData = camelcaseKeys(response?.data ?? {})
    },
    updateChart() {
      const newOptions = {
        xAxis: {
          type: 'datetime',
          tickInterval: 1000 * 3600 * 24,
          min: null,
          max: null
        }
      }

      if (this.filters.dates) {
        const dayDiffs = [1, -1]
        const [min, max] = this.filters.dates.map((dateStr, index) => {
          const dayDiff = dayDiffs[index]
          const date = dateStr ? new Date(dateStr) : null
          return date ? daysAgo(dayDiff, date, true).getTime() : undefined
        })

        newOptions.xAxis.min = min
        newOptions.xAxis.max = max
      }

      this.chart.update(newOptions)

      const { answeredCalls, otherCalls, missedCalls } = this.chartData
      const [answeredCallsSeries, missedCallsSeries, otherCallsSeries] = this.chart.series
      const formatChartData = (data) => Object.keys(data).map((date) => [new Date(date).getTime(), data[date]])
      answeredCallsSeries.setData(formatChartData(answeredCalls))
      otherCallsSeries.setData(formatChartData(otherCalls))
      missedCallsSeries.setData(formatChartData(missedCalls))
    },
    initChart() {
      const chartOptions = {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 48,
          spacingLeft: 12,
          spacingRight: 12
        },
        colors: [
          '#243876',
          '#2563EB',
          '#0C9F6E'
        ],
        title: {
          text: '',
          enable: false
        },
        xAxis: {
          type: 'datetime',
          tickInterval: 1000 * 3600 * 24,
          tickColor: '#9CA3AF',
          labels: {
            style: {
              color: '#9CA3AF'
            }
          }
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            enabled: false
          },
          stackLabels: {
            enabled: false
          },
          gridLineDashStyle: 'dash',
          labels: {
            distance: -15,
            y: -7,
            style: {
              color: '#9CA3AF'
            }
          }
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          symbolRadius: 2,
          floating: false,
          borderWidth: 0,
          shadow: false
        },
        tooltip: {
          borderRadius: 4,
          borderWidth: 0,
          hideDelay: 0,
          outside: true,
          useHTML: true,
          formatter() {
            const thisPoint = this.point
            const allSeries = this.series.chart.series
            const thisIndex = thisPoint.index
            // todo: maybe adjust this one later, but seems fine to me
            const tooltipDate = new Date(this.x).toLocaleDateString('en', { day: 'numeric', month: 'short', year: 'numeric' })
            let tooltipMarkup = `<p class="text-xs font-normal text-gray-500 mb-1">${tooltipDate}</p>`

            const getTooltipLine = (key, value) => (`
              <div class="flex justify-between items-center text-gray-800 text-xs">
                <span>${key}</span>
                <b>${value}</b>
              </div>
            `)

            allSeries.forEach((seriesItem) => {
              if (!seriesItem.points[thisIndex]) return
              tooltipMarkup += getTooltipLine(seriesItem.name, seriesItem.points[thisIndex].y)
            })

            tooltipMarkup += getTooltipLine('Total', this.point.stackTotal)

            return `<div class="incoming-calls__tooltip">${tooltipMarkup}</div>`
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            minPointLength: 2,
            pointPadding: 0.1
          },
          series: {
            maxPointWidth: 25
          }
        },
        series: [
          {
            name: 'Answered Calls',
            data: []
          },
          {
            name: 'Missed Calls',
            data: []
          },
          {
            name: 'Other',
            data: []
          }
        ]
      }

      this.chart = Highcharts.chart('incoming-calls-chart', chartOptions)
    }
  },
  mounted() {
    this.initChart()
  },
  created() {
    this.searchTriggerTimeout = null
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this.searchTriggerTimeout)
        this.searchTriggerTimeout = setTimeout(() => {
          this.fetchChartData()
        }, 500)
      },
      deep: true
    },
    chartData: {
      handler() {
        this.updateChart()
      },
      deep: true
    }
  }
}

</script>

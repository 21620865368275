<template>
  <div class="mb-5">
    <h2 class="h3 m-0 mb-2 font-weight-semi-bold text-dark">ServiceTitan</h2>

    <form action="/" class="narrow-form mb-5" @submit.prevent="onSubmit">
      <div class="form-field mb-3">
        <label>Business Unit</label>
        <VueMultiselect
          v-model="selectedBusinessUnit"
          :options="tenants"
          :internal-search="false"
          :searchable="true"
          track-by="id"
          label="name"
          :loading="isTenantsLoading"
          :max-height="600"
          @search-change="fetchTenants"
          group-values="businessUnits"
          group-label="title"
          :group-select="false">
          <template #option="{ option }">
            <template v-if="option.$groupLabel">
              Tenant: {{ option.$groupLabel }}
            </template>
            <span v-else :class="['ml-3', { 'text-muted': option.$isDisabled }]">{{ option.name }}</span>
          </template>
        </VueMultiselect>

        <small v-if="submitError" class="text-danger">{{ submitError }}</small>
      </div>

      <div class="form-field mb-3">
        <label>Campaign</label>
        <VueMultiselect
          v-model="selectedCampaign"
          :options="tenants"
          :internal-search="false"
          :searchable="true"
          track-by="id"
          label="name"
          :loading="isTenantsLoading"
          :max-height="600"
          @search-change="fetchTenants"
          group-values="campaigns"
          group-label="title"
          :group-select="false">
          <template #option="{ option }">
            <template v-if="option.$groupLabel">
              Tenant: {{ option.$groupLabel }}
            </template>
            <span class="ml-3" v-else>{{ option.name }}</span>
          </template>
        </VueMultiselect>
      </div>

      <button type="submit" class="btn btn-outline rounded">
        Update
      </button>
    </form>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import snakeCaseKeys from 'snakecase-keys'

import axiosTransform from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    businessId: { type: String, required: true },
    serviceTitanBusinessUnit: { type: Object, required: true },
    serviceTitanCampaign: { type: Object, required: true }
  },
  components: {
    VueMultiselect
  },
  data() {
    const selectedBusinessUnit = this.serviceTitanBusinessUnit.id ? { ...this.serviceTitanBusinessUnit } : null
    const selectedCampaign = this.serviceTitanCampaign.id ? { ...this.serviceTitanCampaign } : null
    return {
      isTenantsLoading: false,
      tenants: [],
      selectedBusinessUnit,
      selectedCampaign,
      submitError: null
    }
  },
  methods: {
    fetchTenants(search) {
      this.isTenantsLoading = true
      axios.get('/gb/ajax/service_titan/tenants/index_for_select', {
        params: { search, withBusinessUnits: true, withCampaigns: true },
        paramsSerializer: (json) => qs.stringify(snakeCaseKeys(json)),
        ...axiosTransform
      })
        .then((res) => {
          this.tenants = res.data.map(({ businessUnits, ...tenant }) => ({
            ...tenant,
            businessUnits: businessUnits.map(({ usedInMobileBusiness, ...businessUnit }) => ({
              ...businessUnit,
              $isDisabled: usedInMobileBusiness && this.selectedBusinessUnit?.id !== businessUnit.id
            }))
          }))
        })
        .finally(() => {
          this.isTenantsLoading = false
        })
    },
    onSubmit() {
      this.submitError = null
      return axios.put(
        `/gb/ajax/businesses/${this.businessId}`,
        {
          mobileBusiness: {
            serviceTitanBusinessUnitId: this.selectedBusinessUnit?.id || null,
            serviceTitanCampaignId: this.selectedCampaign?.id || null
          }
        },
        axiosTransform
      )
        .then(() => {
          toastr.success('Successfully saved')
        })
        .catch((err) => {
          if (err.response.status === 422 && err.response.data.type === 'validation') {
            this.submitError = err.response.data.errors.serviceTitanBusinessUnitId.join(', ')
          } else {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
    }
  },
  mounted() {
    this.fetchTenants()
  }
}
</script>

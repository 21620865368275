<template>
  <div class="reporting py-3">
    <CustomTabs
      ref="tabs"
      class="reporting__tabs"
      :list="tabs.list"
      :active-tab="tabs.activeIndex"
      @setActiveTab="setActiveTab" />
    <p class="w-50 pt-4">
      {{ activeInfoText }}
    </p>
    <div class="align-items-end d-flex">
      <div class="form-field">
        <label class="m-0">By date</label>
        <DatePicker
          class="w-100"
          mode="range"
          max-date="today"
          v-model="byCreatedAt" />
      </div>
      <button
        v-if="byCreatedAt.length"
        class="btn btn-link btn--rem gap-3"
        @click="byCreatedAt = []">
        <i class="far fa-eraser" /><span>Clear selection</span>
      </button>
    </div>
    <p class="text-center pt-4" v-if="byCreatedAt.length === 0">
      Showing moments posting statistics for all time
    </p>
    <table v-if="reportingStats.length" class="table mv-businesses-table mt-3">
      <thead>
        <tr>
          <th colspan="1" class="w-20 h-auto p-2 border-transparent" />
          <th colspan="5" class="h-auto p-2 text-center">Moments</th>
        </tr>
        <tr>
          <th
            v-for="(column, index) in columns"
            :class="{'w-20': index === 0, 'text-center': index > 0}"
            :key="column.title">
            {{ column.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ReportingTableRow v-for="category in reportingStats"
          class="text-center"
          :table-source-name="activeTableSourceName"
          :business-id="businessId"
          :by-period="byCreatedAt"
          :key="category.title"
          :row="category" />
      </tbody>
    </table>
    <p v-else-if="!loading" class="font-weight-bold mt-3 text-dark">
      No data available for the selected range
    </p>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import snakecaseKeys from 'snakecase-keys'
import axiosTransform from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import CustomTabs from 'vue_widgets/components/reusable_tabs.vue'
import DatePicker from 'vue_widgets/components/datepicker'
import ReportingTableRow from './reporting/table_row.vue'

const MOMENTS_BY_CATEGORY = 'Moments by category'
const MOMENTS_BY_EMPLOYEE = 'Moments by employee'

export default {
  components: {
    ReportingTableRow,
    DatePicker,
    CustomTabs
  },
  props: {
    reportingByEmployeePath: {
      type: String,
      required: true
    },
    reportingByCategoryPath: {
      type: String,
      required: true
    },
    businessId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    byCreatedAt: [],
    reportingStats: [],
    loading: false,
    tabs: {
      activeIndex: 0,
      list: [
        MOMENTS_BY_CATEGORY,
        MOMENTS_BY_EMPLOYEE
      ]
    }
  }),
  created() {
    this.columns = [
      { key: 'title', title: 'Employee Name' },
      { key: 'pendingCount', title: 'Pending' },
      { key: 'approvedCount', title: 'Approved' },
      { key: 'rejectedCount', title: 'Rejected' },
      { key: 'totalCount', title: 'Total' }
    ]

    const daysAgo = (n) => {
      const d = new Date()
      d.setDate(d.getDate() - Math.abs(n))
      return d
    }
    const DATE_MASK = 'MM/DD/YYYY'

    const to = moment(new Date()).format(DATE_MASK)
    const from = moment(daysAgo(30)).format(DATE_MASK)

    this.byCreatedAt = [from, to]
  },
  computed: {
    reportingSourcePath() {
      if (this.activeTableSourceName === 'category') return this.reportingByCategoryPath
      return this.reportingByEmployeePath
    },
    filters() {
      return {
        byCreatedAt: this.byCreatedAt
      }
    },
    tabsMap() {
      return {
        category: this.tabs.list.indexOf(MOMENTS_BY_CATEGORY),
        employee: this.tabs.list.indexOf(MOMENTS_BY_EMPLOYEE)
      }
    },
    activeTableSourceName() {
      return Object.keys(this.tabsMap)
        .find((key) => this.tabsMap[key] === this.tabs.activeIndex)
    },
    activeInfoText() {
      const texts = {
        category: 'for each category',
        employee: 'by employees'
      }

      return `The number of moments posted ${texts[this.activeTableSourceName]} within the selected date span.
        Moments are filtered by the creation date.
        You can click on numbers to see moments for the selected dates and statuses.`
    }
  },
  methods: {
    async fetchReportingStats() {
      this.loading = true

      try {
        const response = await axios.get(this.reportingSourcePath, {
          params: snakecaseKeys(this.filters),
          ...axiosTransform
        })
        this.reportingStats = response.data
      } catch {
        toastr.error(DEFAULT_ERROR_MESSAGE)
      } finally {
        this.loading = false
      }
    },
    setActiveTab(newIndex) {
      this.tabs.activeIndex = newIndex
    }
  },
  watch: {
    filters: {
      handler() {
        this.fetchReportingStats()
      },
      deep: true
    },
    activeTableSourceName() {
      this.fetchReportingStats()
    }
  }
}
</script>

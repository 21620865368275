<template>
  <div v-if="historicalData.length" class="scheduled-geogrid-details-preview__history scroll ver">
    <div v-for="(title, metric) in ATTRS">
      <h4 class="my-2 ml-2">{{ title }}</h4>

      <div class="d-flex align-items-center gap-4">
        <div class="w-100 chart-wrapper">
          <div :ref="`${metric}Chart`" />

          <div v-if="!formattedData[metric].hasData" class="no-data">
            <p>
              <img :src="require('no_data/historical-data.png')" height="48" width="48"
                alt="no data"
                class="img-fluid">
              No data available for this chart.
            </p>
          </div>
        </div>

        <div class="w-10 p-3">
          <h5>Avg</h5>
          <h4 class="mb-0">{{ formattedData[metric].hasData ? tooltipFormatters[metric](formattedData[metric].average) : 'N/A' }}</h4>
        </div>
      </div>
    </div>
  </div>
  <p v-else class="text-center mt-3">
    <img :src="require('no_data/historical-data.png')" height="48" width="48"
      alt="no data"
      class="img-fluid">
    Data has not been collected yet.
  </p>
</template>

<script>
import Highcharts from 'highcharts'
import highchartsAnnotations from 'highcharts/modules/annotations'

import formatISODatetime from 'helpers/formatters'

highchartsAnnotations(Highcharts)

const metricConfig = {
  atgr: {
    base: {
      colors: ['#377DFF']
    },
    yAxis: {
      reversed: true,
      min: 1,
      max: 21
    }
  },
  agr: {
    base: {
      colors: ['#29BA6C']
    },
    yAxis: {
      reversed: true,
      min: 1,
      max: 21
    }
  },
  solv: {
    base: {
      colors: ['#F9AE34']
    },
    yAxis: {
      reversed: false,
      min: 0,
      max: 100
    }
  }
}

const minMaxLabel = (label, reversed) => {
  if (reversed) {
    return { min: 'max', max: 'min' }[label]
  }

  return label
}

export default {
  props: {
    historicalData: {
      type: Array,
      default: () => ([])
    }
  },
  created() {
    this.ATTRS = {
      agr: 'AGR',
      atgr: 'ATGR',
      solv: 'SoLV'
    }

    const attrKeys = Object.keys(this.ATTRS)
    const initialStats = attrKeys.reduce((mem, metric) => {
      mem[metric] = {
        chartData: [],
        max: { y: Number.NEGATIVE_INFINITY, x: null },
        min: { y: Number.POSITIVE_INFINITY, x: null },
        sum: 0,
        hasData: false
      }

      return mem
    }, {})

    this.formattedData = this.historicalData.reduceRight((mem, item) => {
      attrKeys.forEach((metric) => {
        const itemVal = item[`average${metric[0].toUpperCase()}${metric.slice(1)}`]
        const digitizedItemVal = itemVal || 0

        const dateWithoutTime = new Date(item.createdAt).setHours(0, 0, 0, 0)
        const timestamp = new Date(dateWithoutTime).getTime()

        const point = { x: timestamp, y: itemVal }

        mem[metric].chartData.push(point)
        mem[metric].sum += digitizedItemVal

        if (itemVal !== null) {
          mem[metric].hasData = true
          if (itemVal < mem[metric].min.y) mem[metric].min = point
          if (itemVal > mem[metric].max.y) mem[metric].max = point
        }
      })

      return mem
    }, initialStats)

    attrKeys.forEach((metric) => {
      let average = this.formattedData[metric].sum / this.historicalData.length
      if (metric === 'solv') average = Math.round(average)
      this.formattedData[metric].average = average
    })

    this.tooltipFormatters = {
      atgr: (y) => (y < 21 ? y.toFixed(2) : 21),
      agr: (y) => (y < 21 ? y.toFixed(2) : 'N/A'),
      solv: (y) => `${y || 0}%`
    }
  },
  mounted() {
    const tooltipOptions = {
      backgroundColor: '#3D454D',
      borderRadius: 4,
      borderWidth: 0,
      crosshairs: true,
      hideDelay: 0,
      outside: true,
      padding: 0,
      shadow: false,
      style: {
        fontSize: '12px',
        color: '#fff'
      },
      useHTML: true
    }

    this.charts = Object.entries(this.formattedData).map(([metric, data]) => {
      const tooltipFormatter = this.tooltipFormatters[metric]

      const chartConfig = {
        ...metricConfig[metric].base,
        alignTicks: false,
        title: false,
        chart: {
          height: 145,
          spacingTop: 25
        },
        credits: { enabled: false },
        legend: { enabled: false },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter() {
              return formatISODatetime(new Date(this.value), 'date')
            }
          },
          tickLength: 0
        },
        yAxis: {
          ...metricConfig[metric].yAxis,
          title: { enabled: false }
        },
        series: [{ data: data.chartData }],
        tooltip: {
          ...tooltipOptions,
          formatter() {
            const title = formatISODatetime(new Date(this.x), 'date')
            const value = tooltipFormatter(this.y)

            const elements = [
              title,
              `<b class="ml-4">${value}</b>`
            ]

            const currentIndex = this.series.data.indexOf(this.point)
            if (currentIndex) {
              const prevValue = this.series.data[currentIndex - 1].y
              const diff = this.y - prevValue
              if (diff) {
                const reversedDiff = metricConfig[metric].yAxis.reversed ? -diff : diff

                const diffClass = reversedDiff > 0 ? 'success' : 'danger'
                const prefixDiff = reversedDiff > 0 ? '+' : ''

                elements.push(`<b class="ml-2 badge badge-${diffClass}">${prefixDiff}${tooltipFormatter(reversedDiff)}</b>`)
              }
            }

            return `<div class="tooltip-body">${elements.join('')}</div>`
          }
        }
      }

      // draw average line
      if (data.hasData && (data.average || data.average === 0)) {
        chartConfig.yAxis.plotLines = [{
          color: '#3D454D',
          width: 1,
          value: data.average,
          zIndex: 2,
          dashStyle: 'Dash'
        }]
      }

      // draw min max labels if they both exists and do not equal each other
      if (data.min.x && data.max.x && data.min.y !== data.max.y) {
        const { labels, lines } = ['min', 'max'].reduce((mem, key) => {
          const label = minMaxLabel(key, metricConfig[metric].yAxis.reversed)

          mem.labels.push({
            point: {
              x: data[key].x,
              y: -8,
              xAxis: 0
            },
            shape: 'rect',
            text: `${label[0].toUpperCase() + label.slice(1)}: ${data[key].y}`,
            backgroundColor: '#D5DAE2',
            borderColor: '#D5DAE2'
          })

          mem.lines.push({
            color: '#BDC5D1',
            width: 1,
            value: data[key].x,
            zIndex: 2
          })

          return mem
        }, { lines: [], labels: []})

        chartConfig.annotations = [{ draggable: false, crop: false, labels }]
        chartConfig.xAxis.plotLines = lines
      }

      return Highcharts.chart(this.$refs[`${metric}Chart`][0], chartConfig)
    })
  },
  beforeDestroy() {
    this.charts?.forEach((chart) => chart.destroy())
  }
}
</script>

<template>
  <div
    class="geobooster-configurator-fieldset">
    <div class="geobooster-configurator-fieldset__head"
      :class="{ active }"
      @click="toggleActive">
      <slot name="title" />
      <i class="fa-solid fa-chevron-up" />
    </div>
    <div
      class="geobooster-configurator-fieldset__content"
      :style="{ height: contentHeight }"
      :class="{ active }">
      <div
        ref="content"
        class="geobooster-configurator-fieldset__wrapper"
        :class="{ active: !contentHidden }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: false,
    contentHidden: true,
    contentHeight: null
  }),
  methods: {
    setContentHeight() {
      if (!this.active) {
        this.contentHeight = null
        return
      }

      const height = this.$refs.content.scrollHeight
      this.contentHeight = `${height}px`
    },
    toggleActive() {
      this.active = !this.active
    }
  },
  mounted() {
    this.observer = new MutationObserver(() => {
      this.contentHeight = null
      this.$nextTick(this.setContentHeight)
    })
    this.observer.observe(this.$refs.content, { childList: true })
  },
  destroyed() {
    if (!this.observer) return
    this.observer.disconnect()
  },
  watch: {
    active() {
      this.setContentHeight()

      if (!this.active) {
        this.contentHidden = true
        return
      }

      setTimeout(() => {
        this.contentHidden = false
      }, 300)
    }
  }
}
</script>

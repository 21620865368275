<template>
  <div class="phone-input">
    <input
      v-bind="$attrs"
      ref="input"
      class="phone-input__input"
      :class="{
        'error': errorText
      }"
      @change="onChange">
    <div
      v-if="errorText"
      class="phone-input__error">
      {{ errorText }}
    </div>
  </div>
</template>

<script>

import intlTelInput from 'intl-tel-input/intlTelInputWithUtils'

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    errorText: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      itiInstance: null
    }
  },
  methods: {
    initInput() {
      intlTelInput(this.$refs.input, {
        autoPlaceholder: 'aggressive',
        initialCountry: 'us',
        onlyCountries: [],
        separateDialCode: true
      })

      this.itiInstance = intlTelInput.getInstance(this.$refs.input)
      this.itiInstance.setNumber(this.value)
    },
    getErrorObject: (errorText) => ({
      ringThroughNumber: [errorText]
    }),
    onChange() {
      const iti = this.itiInstance

      if (iti.isValidNumber() === null) return

      this.$emit('input', iti.getNumber())

      const KNOWN_ERRORS = {
        2: 'Number is too short.',
        3: 'Number is too long.'
      }

      const errorCode = iti.getValidationError()
      if (errorCode === 0) return

      this.$emit('updateErrors', this.getErrorObject(KNOWN_ERRORS[errorCode] || 'Invalid number.'))
    }
  },
  mounted() {
    this.$nextTick(this.initInput)
  }
}
</script>

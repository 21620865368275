import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import PhoneNumbersIndex from 'vue_widgets/jensen/phone_numbers/index.vue'
import PhoneNumbersNew from 'vue_widgets/jensen/phone_numbers/new.vue'
import PhoneNumbersEdit from 'vue_widgets/jensen/phone_numbers/edit.vue'

Styxie.Initializers.JensenPhoneNumbers = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-phone-numbers-index'),
        render: (h) => h(PhoneNumbersIndex, { props: camelCaseKeys(params) })
      })
    })
  },
  new: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-phone-numbers-form'),
        render: (h) => h(PhoneNumbersNew, { props: camelCaseKeys(params) })
      })
    })
  },
  edit: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-phone-numbers-configure'),
        render: (h) => h(PhoneNumbersEdit, { props: camelCaseKeys(params) })
      })
    })
  }
}

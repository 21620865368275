<template>
  <div
    v-if="stats"
    class="incoming-calls-stats">
    <div v-for="metricName in METRICS_ORDER"
      class="incoming-calls-stats__card"
      :key="metricName">
      <p class="incoming-calls-stats__title">
        {{ HUMAN_METRIC_NAMES[metricName] }}
      </p>
      <div class="incoming-calls-stats__line">
        <p>
          {{ extendedStats[metricName].current }}
        </p>
        <div
          v-if="extendedStats[metricName].previous !== null"
          class="incoming-calls-stats__compare">
          <span
            v-if="typeof extendedStats[metricName].delta.value !== 'undefined'"
            class="incoming-calls-stats__delta"
            :class="`bg-${extendedStats[metricName].delta.colors.background} text-${extendedStats[metricName].delta.colors.text}`">
            <span v-if="extendedStats[metricName].delta.negative">
              <i class="fa-regular fa-arrow-down-right"
                :class="extendedStats[metricName].delta.colors.text" />
            </span>
            <span v-else-if="extendedStats[metricName].delta.value !== 0">
              <i class="fa-regular fa-arrow-up-right"
                :class="extendedStats[metricName].delta.colors.text" />
            </span>
            <span>{{ extendedStats[metricName].delta.value }}%</span>
          </span>
          <span class="incoming-calls-stats__previous">{{ extendedStats[metricName].previous }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axiosTransform from 'common/axios'
import snakecaseKeys from 'snakecase-keys'
import axios from 'axios'

const reversedNegativeMetrics = new Set(['missedCalls'])

export default {
  props: {
    filters: {
      type: Object,
      required: false,
      default: () => {}
    },
    fetchOnMounted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    stats: null
  }),
  methods: {
    async updateStats() {
      const newStats = await this.getStats()
      this.stats = newStats
    },
    async getStats() {
      let response = null
      try {
        response = await axios.get(
          '/call_tracking/ajax/incoming_calls/stats',
          {
            params: this.filters,
            paramsSerializer(json) {
              return qs.stringify(snakecaseKeys(json), { arrayFormat: 'brackets' })
            },
            ...axiosTransform
          }
        )
      } catch (error) {
        toastr.error(error)
      }

      return response?.data ?? {}
    }
  },
  created() {
    this.searchTriggerTimeout = null

    this.METRICS_ORDER = [
      'totalCalls',
      'totalMinutes',
      'answeredCalls',
      'missedCalls',
      'recordedVoicemails'
    ]

    this.HUMAN_METRIC_NAMES = {
      totalCalls: 'Total Calls',
      totalMinutes: 'Total Minutes',
      answeredCalls: 'Answered',
      missedCalls: 'Missed',
      recordedVoicemails: 'Recorded voicemail'
    }
  },
  mounted() {
    if (!this.fetchOnMounted) return
    this.updateStats()
  },
  computed: {
    extendedStats() {
      if (!this.stats) return {}
      const extendedStatsObj = {}

      const getDiffColorPalette = (delta, metricName) => {
        const negative = delta < 0

        if (delta === 0) {
          return {
            text: 'gray-900',
            background: 'gray-100'
          }
        }

        const isPositiveTrend = reversedNegativeMetrics.has(metricName) ? negative : !negative
        if (isPositiveTrend) {
          return {
            text: 'green-800',
            background: 'green-100'
          }
        }

        return {
          text: 'red-800',
          background: 'red-100'
        }
      }

      Object.keys(this.stats).forEach((metricName) => {
        const metric = this.stats[metricName]
        const { current, previous } = metric
        let delta = (current - previous) / previous * 100

        if (current > 0 && previous === 0) {
          delta = 100
        } else if (current === 0 && previous === 0) {
          delta = 0
        }

        const value = Math.abs(parseInt(delta, 10))
        const negative = delta < 0
        const colors = getDiffColorPalette(delta, metricName)

        extendedStatsObj[metricName] = {
          ...metric,
          delta: {
            value,
            negative,
            colors
          }
        }
      })

      return extendedStatsObj
    }
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this.searchTriggerTimeout)
        this.searchTriggerTimeout = setTimeout(() => {
          this.updateStats()
        }, 500)
      },
      deep: true
    }
  }
}
</script>

<template>
  <div class="blocklist">
    <h1 class="blocklist__title">Update Blocklist</h1>
    <div class="blocklist__text">
      Add any incoming call to the block list and all subsequent calls will be dropped before they enter the call queue.
      Blocked callers will receive a message that the number they use had been blocked.
      You can also use the asterisk ("*") wildcard for one or more digits of the number to be blocked.
    </div>
    <form class="blocklist__form" @submit.prevent="addPattern">
      <label for="blocklist">Add numbers to block</label>
      <textarea
        v-model="textPatterns"
        name="blocklist"
        id="blocklist-textarea"
        placeholder="Add a new number or pattern, one per line" />
      <button class="btn btn-xs btn-primary">
        Update blocklist
      </button>
    </form>
    <VueSlimTable
      ref="table"
      :source="getBlockedNumbers"
      :columns="columns"
      class="responsive-table borderless-table m-0 blocklist__table">
      <template #row="{ row }">
        <tr>
          <td class="text-truncate" :title="row.pattern">
            {{ row.pattern }}
          </td>
          <td class="text-end">
            <div class="blocklist__actions">
              <button class="btn btn-xs btn-danger" @click="removePattern(row.pattern)">Remove</button>
            </div>
          </td>
        </tr>
      </template>
      <template #pagination>
        {{ '' }}
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import { runSwal } from 'common/delete_with_swal'

const { transformRequest } = axiosTransform
export default {
  data() {
    return {
      textPatterns: ''
    }
  },
  created() {
    this.columns = [
      { title: 'Number/Pattern', key: 'pattern' },
      { title: 'Action', key: 'action' }
    ]

    this.allowCharactersRegex = /[^0-9*\n]/g
  },
  methods: {
    getBlockedNumbers() {
      return axios.get('/call_tracking/ajax/blocklist')
        .then((res) => res.data)
    },
    addPattern() {
      return axios.put('/call_tracking/ajax/blocklist', {
        addPatterns: this.patterns
      }, {
        transformRequest
      })
        .then(() => {
          this.reload()
          this.textPatterns = ''
        })
    },
    removePattern(pattern) {
      runSwal(() => {
        axios.put('/call_tracking/ajax/blocklist', {
          removePattern: pattern
        }, {
          transformRequest
        })
          .then(() => {
            this.reload()
            this.textPatterns = ''
          })
      })
    },
    reload() {
      this.$refs.table.reload()
    }
  },
  computed: {
    patterns() {
      const allPatterns = this.textPatterns.split('\n')
      return allPatterns
        .map((pattern) => pattern.replace(this.allowCharactersRegex, ''))
        .filter((pattern) => pattern.trim())
    }
  }
}
</script>

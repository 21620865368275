<template>
  <div class="radio-group">
    <AppRadio
      v-for="({ value: optionValue, label }) in options"
      :key="getUniqueRadioItemName(optionValue)"
      :id-name="getUniqueRadioItemName(optionValue)"
      :name="name"
      :item="optionValue"
      :value="value"
      @input="$emit('input', $event)">
      {{ label }}
    </AppRadio>
  </div>
</template>

<script>
import AppRadio from './radio'

export default {
  components: {
    AppRadio
  },
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: [Array, Object],
      required: true
    }
  },
  methods: {
    getUniqueRadioItemName(radioItemValue) {
      return `${this.name}${radioItemValue}`
    }
  }
}
</script>

import moment from 'moment'

import AppearanceStep from '../../edit_steps/geobooster_widgets_appearance'
import required from '../../validators/required'
import themePresetsMixin from '../themePresetsMixin'
import equalPrimitiveObjects from '../../utils/equalPrimitiveObjects'

export default {
  mixins: [themePresetsMixin],
  components: {
    AppearanceStep
  },
  data: () => ({
    steps: {
      appearance: {
        font: {
          // this field is not shown at the moment
          component: 'CustomSelect',
          name: 'font',
          value: 'Arial',
          values: [
            {
              text: 'Arial',
              value: 'Arial'
            }
          ],
          label: 'Font',
          error: false,
          errorText: '',
          disabled: true
        },
        momentCardMediaPosition: {
          component: 'Radio',
          name: 'momentCardMediaPosition',
          value: 'top',
          /* eslint-disable max-len */
          values: [
            {
              text: 'Top',
              value: 'top',
              svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 4L14.5 12C14.5 12.8284 13.8284 13.5 13 13.5L3 13.5C2.17157 13.5 1.5 12.8284 1.5 12L1.5 4C1.5 3.17157 2.17157 2.5 3 2.5L13 2.5C13.8284 2.5 14.5 3.17157 14.5 4ZM13 1C14.6569 1 16 2.34315 16 4L16 12C16 13.6569 14.6569 15 13 15L3 15C1.34315 15 0 13.6569 0 12V4C0 2.34315 1.34315 1 3 1H13ZM4.5 4C3.67157 4 3 4.67157 3 5.5V7.5C3 8.32843 3.67157 9 4.5 9H11.5C12.3284 9 13 8.32843 13 7.5V5.5C13 4.67157 12.3284 4 11.5 4H4.5Z" fill="#77838F"/> </svg> '
            },
            {
              text: 'Right',
              value: 'right',
              svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 4L14.5 12C14.5 12.8284 13.8284 13.5 13 13.5L3 13.5C2.17157 13.5 1.5 12.8284 1.5 12L1.5 4C1.5 3.17157 2.17157 2.5 3 2.5L13 2.5C13.8284 2.5 14.5 3.17157 14.5 4ZM13 1C14.6569 1 16 2.34315 16 4L16 12C16 13.6569 14.6569 15 13 15L3 15C1.34315 15 0 13.6569 0 12V4C0 2.34315 1.34315 1 3 1H13ZM8.5 4C7.67157 4 7 4.67157 7 5.5V10.5C7 11.3284 7.67157 12 8.5 12H11.5C12.3284 12 13 11.3284 13 10.5V5.5C13 4.67157 12.3284 4 11.5 4H8.5Z" fill="#77838F"/> </svg> '
            },
            {
              text: 'Bottom',
              value: 'bottom',
              svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 4L14.5 12C14.5 12.8284 13.8284 13.5 13 13.5L3 13.5C2.17157 13.5 1.5 12.8284 1.5 12L1.5 4C1.5 3.17157 2.17157 2.5 3 2.5L13 2.5C13.8284 2.5 14.5 3.17157 14.5 4ZM13 1C14.6569 1 16 2.34315 16 4L16 12C16 13.6569 14.6569 15 13 15L3 15C1.34315 15 0 13.6569 0 12V4C0 2.34315 1.34315 1 3 1H13ZM4.5 7C3.67157 7 3 7.67157 3 8.5V10.5C3 11.3284 3.67157 12 4.5 12H11.5C12.3284 12 13 11.3284 13 10.5V8.5C13 7.67157 12.3284 7 11.5 7H4.5Z" fill="#77838F"/> </svg> '
            },
            {
              text: 'Left',
              value: 'left',
              svg: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 4L14.5 12C14.5 12.8284 13.8284 13.5 13 13.5L3 13.5C2.17157 13.5 1.5 12.8284 1.5 12L1.5 4C1.5 3.17157 2.17157 2.5 3 2.5L13 2.5C13.8284 2.5 14.5 3.17157 14.5 4ZM13 1C14.6569 1 16 2.34315 16 4L16 12C16 13.6569 14.6569 15 13 15L3 15C1.34315 15 0 13.6569 0 12V4C0 2.34315 1.34315 1 3 1H13ZM4.5 4C3.67157 4 3 4.67157 3 5.5V10.5C3 11.3284 3.67157 12 4.5 12H7.5C8.32843 12 9 11.3284 9 10.5V5.5C9 4.67157 8.32843 4 7.5 4H4.5Z" fill="#77838F"/> </svg> '
            }
          ],
          /* eslint-enable max-len */
          label: 'Moment card media position',
          error: false,
          errorText: ''
        },
        themeSelect: {
          component: 'ThemeSelect',
          value: {
            themeType: 'light',
            selectedThemeIndex: 0
          },
          values: [
            {
              text: 'Light',
              value: 'light'
            },
            {
              text: 'Dark',
              value: 'dark'
            },
            {
              text: 'Custom',
              value: 'custom'
            }
          ]
        },
        backgroundColor: {
          component: 'ColorPicker',
          name: 'backgroundColor',
          value: '#FFFFFF',
          defaultValue: '#FFFFFF',
          label: 'Background color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        momentCardBgColor: {
          component: 'ColorPicker',
          name: 'momentCardBgColor',
          value: '#FFFFFF',
          defaultValue: '#FFFFFF',
          label: 'Card moment background color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        borderColor: {
          component: 'ColorPicker',
          name: 'borderColor',
          value: '#DCDCDC',
          defaultValue: '#DCDCDC',
          label: 'Border color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        customerNameColor: {
          component: 'ColorPicker',
          name: 'customerNameColor',
          value: '#377DFF',
          defaultValue: '#377DFF',
          label: 'Customer color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        textColor: {
          component: 'ColorPicker',
          name: 'textColor',
          value: '#3D454D',
          defaultValue: '#3D454D',
          label: 'Text color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        dateColor: {
          component: 'ColorPicker',
          name: 'textColor',
          value: '#B1B1B1',
          defaultValue: '#B1B1B1',
          label: 'Date color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        widgetLayout: {
          component: 'Radio',
          name: 'widgetLayout',
          value: 'full',
          values: [
            {
              text: 'Default',
              value: 'full'
            },
            {
              text: 'Map only',
              value: 'map'
            },
            {
              text: 'No map',
              value: 'no_map'
            }
          ],
          label: 'Widget layout',
          error: false,
          errorText: ''
        },
        momentListLayout: {
          component: 'Radio',
          name: 'momentListLayout',
          value: 'tile',
          defaultValue: 'tile',
          values: [
            {
              text: 'Media Slider',
              value: 'slider',
              icon: 'fa-regular fa-gallery-thumbnails'
            },
            {
              text: 'Media Tiles',
              value: 'tile',
              icon: 'fa-regular fa-grid-2'
            }
          ],
          label: 'Moments list layout',
          error: false,
          errorText: ''
        },
        momentCardLayout: {
          component: 'Radio',
          name: 'momentCardLayout',
          value: 'tile',
          defaultValue: 'tile',
          values: [
            {
              text: 'Match heights',
              value: 'slider'
            },
            {
              text: 'Content height',
              value: 'tile'
            }
          ],
          label: 'Card moment layout',
          error: false,
          errorText: ''
        },
        momentCardCssClass: {
          component: 'Radio',
          name: 'momentCardCssClass',
          value: 'preview-small',
          defaultValue: 'preview-small',
          values: [
            {
              text: 'Small',
              value: 'preview-small'
            },
            {
              text: 'Medium',
              value: 'preview-medium'
            },
            {
              text: 'Large',
              value: 'preview-large'
            }
          ],
          label: 'Media preview size',
          error: false,
          errorText: ''
        },
        dateFormat: {
          component: 'CustomSelect',
          name: 'dateFormat',
          value: 'MMM DD, YYYY',
          defaultValue: 'MMM DD, YYYY',
          values: [
            {
              text: 'Oct 27, 2021',
              value: 'MMM DD, YYYY'
            },
            // => "17.02.2023"
            {
              text: '27.10.2021',
              value: 'DD.MM.YYYY'
            },
            // => "02/17/2023"
            {
              text: '10/27/2021',
              value: 'MM/DD/YYYY'
            },
            // => "02.17.2023"
            {
              text: '10.27.2021',
              value: 'MM.DD.YYYY'
            },
            // => "17.02.2023 19:30"
            {
              text: '27.10.2021 19:30',
              value: 'DD.MM.YYYY HH:mm'
            },
            // => "02.17.2023 07:30 PM"
            {
              text: '10.27.2021 07:30 PM',
              value: 'MM.DD.YYYY hh:MM A'
            }
          ],
          label: 'Date format',
          error: false,
          errorText: ''
        },
        categoryTextColor: {
          component: 'ColorPicker',
          name: 'categoryTextColor',
          value: '#3D454D',
          defaultValue: '#3D454D',
          label: 'Category/Business color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        categoryBgColor: {
          component: 'ColorPicker',
          name: 'categoryBgColor',
          value: '#EDF0F4',
          defaultValue: '#EDF0F4',
          label: 'Category bg color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        momentCardBackgroundType: {
          component: 'Radio',
          name: 'momentCardBackgroundType',
          value: 'monotone',
          defaultValue: 'monotone',
          values: [
            {
              text: 'Flat color',
              value: 'monotone'
            },
            {
              text: 'Gradient',
              value: 'gradient'
            }
          ],
          label: 'Card background type'
        },
        gradientRotation: {
          component: 'CustomInput',
          name: 'gradientRotation',
          type: 'number',
          value: 0,
          defaultValue: 0,
          label: 'Gradient rotation (degrees)',
          error: false,
          errorText: ''
        },
        gradientFirstColor: {
          component: 'ColorPicker',
          name: 'gradientFirstColor',
          value: '',
          defaultValue: '',
          label: 'Gradient first color',
          error: false,
          errorText: ''
        },
        gradientFirstStop: {
          component: 'CustomInput',
          name: 'gradientFirstStop',
          type: 'number',
          value: 0,
          label: 'Gradient first stop',
          error: false,
          errorText: ''
        },
        gradientSecondColor: {
          component: 'ColorPicker',
          name: 'gradientSecondColor',
          value: '',
          defaultValue: '',
          label: 'Gradient second color',
          error: false,
          errorText: ''
        },
        gradientSecondStop: {
          component: 'CustomInput',
          name: 'gradientSecondStop',
          type: 'number',
          value: 100,
          label: 'Gradient second stop',
          error: false,
          errorText: ''
        },
        mapMarkerColor: {
          component: 'ColorPicker',
          name: 'mapMarkerColor',
          value: '#0942D3',
          defaultValue: '#0942D3',
          label: 'Map marker color',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        mapMarkerIcon: {
          component: 'CustomSelect',
          name: 'mapMarkerIcon',
          value: 'flag',
          values: [
            {
              text: 'Flag',
              value: 'flag',
              icon: 'fa-solid fa-flag text-primary'
            },
            {
              text: 'House',
              value: 'house',
              icon: 'fa-solid fa-house text-primary'
            },
            {
              text: 'Garage',
              value: 'garage',
              icon: 'fa-solid fa-garage text-primary'
            },
            {
              text: 'Star',
              value: 'star',
              icon: 'fa-solid fa-star text-primary'
            },
            {
              text: 'Plunger',
              value: 'plunger',
              icon: 'fa-solid fa-sink text-primary'
            },
            {
              text: 'Pin',
              value: 'google',
              icon: 'fa-solid fa-location-dot text-primary'
            },
            {
              text: 'Custom',
              value: 'custom',
              icon: 'fa-regular fa-symbols text-primary'
            }
          ],
          label: 'Map marker icon',
          error: false,
          errorText: ''
        },
        showCustomMapMarkerWrapper: {
          component: 'CustomCheckbox',
          name: 'showCustomMapMarkerWrapper',
          value: true,
          label: 'Show custom marker inside a wrapper',
          error: false,
          errorText: ''
        },
        customMapMarkerIcon: {
          component: 'MapMarkerSelect',
          name: 'customMapMarkerIcon',
          value: null,
          error: false,
          errorText: '',
          validationFields: ['mapMarkerIcon'],
          validators: [
            (value, { mapMarkerIcon }) => {
              if (mapMarkerIcon.value === 'custom' && !value?.url) {
                return {
                  error: true,
                  errorMessage: 'Please upload a map marker image or select non-custom icon preset'
                }
              }

              return { error: false }
            }
          ]
        },
        customMapMarkerWarning: {
          component: 'InfoPanel',
          name: 'customMapMarkerWarning',
          value: 'The custom icon color changes on the map only after saving.'
        }
      }
    }
  }),
  methods: {
    checkForDefaultValue(key) {
      return this.steps.appearance[key].value === this.steps.appearance[key].defaultValue
    },
    getValueIfNotDefault(key) {
      return this.checkForDefaultValue(key) ? undefined : this.steps.appearance[key].value
    },
    setDateFormatSelectValues() {
      const dateFormats = this.steps.appearance.dateFormat.values
      dateFormats.forEach((value, index) => {
        dateFormats[index].text = moment().format(value.value)
      })
    },
    applyTheme(theme) {
      Object.keys(theme).forEach((key) => {
        const field = theme[key]
        const appearanceField = this.steps.appearance[key]
        if (!appearanceField) return
        appearanceField.value = field
        appearanceField.defaultValue = field
      })
    },
    changeTheme({ themeType, selectedThemeIndex }) {
      const { themeSelect } = this.steps.appearance
      let newThemeIndex = selectedThemeIndex

      if (themeSelect.value.themeType !== themeType) {
        newThemeIndex = 0
      }

      themeSelect.value = {
        themeType,
        selectedThemeIndex: newThemeIndex
      }

      this.$nextTick(() => {
        this.applyTheme(this.themeFromSelect)
      })
    },
    setThemeFromFields() {
      const searchableThemes = this.themes[this.momentCardBackgroundType]

      let themeIndex = 0
      /* eslint-disable no-unused-vars */
      const themeComparator = ({ themeName, ...theme }, matchingThemeIndex) => {
        const {
          backgroundColor,
          borderColor,
          mapMarkerColor,
          ...cardMomentTheme
        } = this.currentTheme

        let combarableTheme = cardMomentTheme

        if (this.momentCardBackgroundType === 'gradient') {
          const {
            cardMomentBackground,
            ...themeWithoutBackground
          } = cardMomentTheme
          combarableTheme = themeWithoutBackground
        } else {
          const {
            gradientFirstColor,
            gradientSecondColor,
            gradientRotation,
            gradientFirstStop,
            gradientSecondStop,
            ...themeWithoutGradient
          } = cardMomentTheme
          combarableTheme = themeWithoutGradient
        }

        const themeMatches = equalPrimitiveObjects(theme, combarableTheme)
        if (!themeMatches) {
          return false
        }

        themeIndex = matchingThemeIndex
        return true
      }
      /* eslint-enable no-unused-vars */

      const setThemeInSelect = (themeType) => {
        this.steps.appearance.themeSelect.value = {
          themeType,
          selectedThemeIndex: themeIndex
        }
      }

      const oneOfDarkThemes = searchableThemes.dark.find(themeComparator)

      if (oneOfDarkThemes) {
        setThemeInSelect('dark')
        return
      }

      const oneOfLightThemes = searchableThemes.light.find(themeComparator)

      if (oneOfLightThemes) {
        setThemeInSelect('light')
        return
      }

      setThemeInSelect('custom')
    }
  },
  computed: {
    currentTheme() {
      return {
        backgroundColor: this.steps.appearance.backgroundColor.value.toUpperCase(),
        momentCardBgColor: this.steps.appearance.momentCardBgColor.value.toUpperCase(),
        borderColor: this.steps.appearance.borderColor.value.toUpperCase(),
        customerNameColor: this.steps.appearance.customerNameColor.value.toUpperCase(),
        textColor: this.steps.appearance.textColor.value.toUpperCase(),
        dateColor: this.steps.appearance.dateColor.value.toUpperCase(),
        mapMarkerColor: this.steps.appearance.mapMarkerColor.value.toUpperCase(),
        categoryTextColor: this.steps.appearance.categoryTextColor.value.toUpperCase(),
        categoryBgColor: this.steps.appearance.categoryBgColor.value.toUpperCase(),
        gradientFirstColor: this.steps.appearance.gradientFirstColor.value.toUpperCase(),
        gradientSecondColor: this.steps.appearance.gradientSecondColor.value.toUpperCase(),
        gradientRotation: this.steps.appearance.gradientRotation.value,
        gradientFirstStop: this.steps.appearance.gradientFirstStop.value,
        gradientSecondStop: this.steps.appearance.gradientSecondStop.value
      }
    },
    themesList() {
      const step = this.steps.appearance
      const { themeType } = step.themeSelect.value
      return this.themes[this.momentCardBackgroundType][themeType]
    },
    themeFromSelect() {
      const { selectedThemeIndex } = this.steps.appearance.themeSelect.value
      return this.themesList[selectedThemeIndex]
    },
    momentCardBackgroundType() {
      return this.steps.appearance.momentCardBackgroundType.value
    },
    gradientRotationValue() {
      return this.steps.appearance.gradientRotation.value
    },
    gradientFirstStopValue() {
      return this.steps.appearance.gradientFirstStop.value
    },

    gradientSecondStopValue() {
      return this.steps.appearance.gradientSecondStop.value
    }
  },
  mounted() {
    this.setDateFormatSelectValues()
  },
  watch: {
    currentTheme: {
      handler() {
        this.setThemeFromFields()
      },
      deep: true
    },
    momentCardBackgroundType() {
      const themeTypeValue = this.steps.appearance.themeSelect.value
      themeTypeValue.selectedThemeIndex = 0

      this.$nextTick(() => {
        this.applyTheme(this.themeFromSelect)
      })
    },
    gradientRotationValue(newValue) {
      this.$nextTick(() => {
        if (typeof newValue === 'number') return
        this.steps.appearance.gradientRotation.value = 0
      })
    },
    gradientFirstStopValue(newValue) {
      this.$nextTick(() => {
        if (typeof newValue !== 'number') {
          this.steps.appearance.gradientFirstStop.value = 0
          return
        }

        if (newValue > 100) {
          this.steps.appearance.gradientFirstStop.value = 100
        }
      })
    },
    gradientSecondStopValue(newValue) {
      this.$nextTick(() => {
        if (typeof newValue !== 'number') {
          this.steps.appearance.gradientSecondStop.value = 0
          return
        }

        if (newValue > 100) {
          this.steps.appearance.gradientSecondStop.value = 100
        }
      })
    }
  }
}

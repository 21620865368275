<template>
  <div class="moments-sidebar" :class="{ active: mutableActive }">
    <div class="moments-sidebar__top">
      <div class="moments-sidebar__head">
        <div class="moments-sidebar__name">{{ businessName }}</div>
        <button class="moments-sidebar__close" @click="closeSidebar">Close <i class="fa-regular fa-xmark" /></button>
      </div>
      <div class="moments-sidebar__line">
        <div v-if="date" class="moments-sidebar__date">{{ date }}</div>
        <a v-if="canCreateNewMoment"
          :class="['moments-sidebar__new btn btn-primary btn-xs gap-3 rounded js-add-moment', { disabled: isEditingDisabled }]"
          :href="newMomentLink"
          target="_blank">
          <i class="far fa-circle-plus fa-fw" />
          <span>
            Add moment
          </span>
        </a>
      </div>
    </div>

    <AppTabs
      v-if="stEnabled && params.serviceTitanBusinessUnit"
      :list="TABS_TITLES"
      :active-tab="activeTabIndex"
      :disabled-tabs="[]"
      @setActiveTab="activeTabIndex = $event">
      <template v-if="!fetchingMoments" #after:0>
        <small class="moments-sidebar__records-count">{{ totalMoments > 100 ? '99+' : totalMoments }}</small>
      </template>
      <template v-if="!fetchingStJobs" #after:1>
        <small class="moments-sidebar__records-count">{{ totalStJobs > 100 ? '99+' : totalStJobs }}</small>
      </template>
    </AppTabs>

    <div v-show="activeTabIndex === 0" ref="momentListNode" class="moments-sidebar__list scroll ver">
      <div v-if="fetchingMoments" class="d-flex justify-content-center align-items-center h-100">
        <AppSpinner />
      </div>
      <div v-else class="moments-sidebar__wrapper">
        <MomentElement
          v-for="moment in fetchedMoments"
          class="moments-sidebar__card"
          :key="moment.obfuscatedId"
          :checkin="moment"
          :allow-actions="false"
          @openPreview="openPreview" />
      </div>
    </div>

    <div v-show="activeTabIndex === 1" ref="momentListNode" class="moments-sidebar__list scroll ver">
      <div v-if="fetchingStJobs" class="d-flex justify-content-center align-items-center h-100">
        <AppSpinner />
      </div>
      <div v-else class="moments-sidebar__wrapper">
        <StJobElement
          v-for="stJob in fetchedStJobs"
          class="moments-sidebar__card"
          :key="stJob.id"
          :st-job="stJob"
          :allow-actions="false"
          :st-job-generating-id="stJobGeneratingId"
          @openPreview="openPreview"
          @convertJob2Moment="$emit('convertJob2Moment', $event)" />
      </div>
    </div>

    <PreviewImages v-if="mediaIndex !== null"
      :media="mediaSet"
      :current-media-index="mediaIndex"
      @close="closeImage" />
  </div>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import axiosTransform from 'common/axios'

import AppTabs from 'vue_widgets/components/reusable_tabs'
import AppSpinner from 'vue_widgets/components/spinner'
import { pick } from 'common/helpers'
import MomentElement from '../checkins/moment_element.vue'
import StJobElement from './service_titan_job_element.vue'
import PreviewImages from '../../gallery/preview_images'

import { BASE_AJAX_URL } from '../checkins/helpers.js'

const canCreateNewMoment = (dateStr) => {
  const today = new Date();
  ['setHours', 'setMinutes', 'setSeconds', 'setMilliseconds'].forEach((key) => today[key](0))
  return new Date(dateStr) >= today
}

const buildAxiosParams = (requestParams) => ({
  params: { ...requestParams, page: 1, perPage: 100 },
  paramsSerializer(json) {
    return qs.stringify(snakeCaseKeys(json, { deep: true }), { arrayFormat: 'brackets' })
  },
  ...axiosTransform
})

export default {
  components: {
    MomentElement,
    StJobElement,
    PreviewImages,
    AppTabs,
    AppSpinner
  },
  props: {
    stEnabled: { type: Boolean, default: false },
    stJobGeneratingId: { type: Number },
    active: { type: Boolean, required: true },
    businessName: { type: String, required: true },
    newMomentLink: { type: String, required: true },
    date: { type: String, required: false, default: null },
    params: { type: Object, required: false }
  },
  data() {
    return {
      mutableActive: false,
      fetchingMoments: false,
      fetchedMoments: [],
      mediaIndex: null,
      mediaSet: null,
      isEditingDisabled: !Styxie.USER.editor,
      activeTabIndex: this.params.tabIndex,
      fetchingStJobs: false,
      fetchedStJobs: [],
      totalMoments: 0,
      totalStJobs: 0
    }
  },
  methods: {
    getRecords() {
      this.fetchingMoments = true
      const checkinsRequestParams = pick(this.params, ['business', 'serviceTitanBusinessUnit', 'byPublishingDate'])
      axios.get(BASE_AJAX_URL, buildAxiosParams(checkinsRequestParams))
        .then((res) => {
          this.totalMoments = res.headers['total-count']
          this.fetchedMoments = res.data
          this.$refs.momentListNode?.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        })
        .finally(() => {
          this.fetchingMoments = false
        })

      if (this.params.serviceTitanBusinessUnit) {
        this.fetchingStJobs = true
        const jobsRequestParams = { ...checkinsRequestParams }
        jobsRequestParams.readyForCheckin = true
        axios.get('/gb/ajax/service_titan/jobs', buildAxiosParams(jobsRequestParams))
          .then((res) => {
            this.totalStJobs = res.headers['total-count']
            this.fetchedStJobs = res.data
          })
          .finally(() => {
            this.fetchingStJobs = false
          })
      }
    },
    closeSidebar() {
      this.mutableActive = false
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
    openPreview({ mediaSet, mediaIndex }) {
      this.mediaSet = mediaSet
      this.mediaIndex = mediaIndex
    },
    closeImage() {
      this.mediaSet = null
      this.mediaIndex = null
    }
  },
  created() {
    this.canCreateNewMoment = canCreateNewMoment(this.date)
    this.TABS_TITLES = [
      'Moments',
      'Service Titan'
    ]
  },
  mounted() {
    this.getRecords()
  },
  watch: {
    active: {
      handler(newVal) {
        if (newVal) {
          setTimeout(() => {
            this.mutableActive = true
          })
        }
      },
      immediate: true
    },
    params: {
      handler(params) {
        this.getRecords()
        this.activeTabIndex = params.tabIndex
      },
      deep: true
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4 pb-2">
      <h1 class="h3 font-weight-semi-bold text-dark m-0">ServiceTitan Jobs</h1>
    </div>
    <STFilter :service-titan-tenants="serviceTitanTenants" @input="applyFilters" />
    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      :per-page="perPage"
      class="responsive-table borderless-table m-0">
      <template #row="{ row }">
        <TableRow :row="row"
          @reload="reload" />
      </template>
      <template #pagination>
        <Pagination :page="currentPage" :per-page="perPage" :total-count="totalRecords" @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import snakeCaseKeys from 'snakecase-keys'
import Pagination from 'vue_widgets/components/pagination'
import STFilter from './filter'
import TableRow from './table_row'

export default {
  components: { Pagination, TableRow, STFilter },
  props: {
    baseUrl: { type: String, required: true },
    serviceTitanTenants: { type: Array, default: () => []}
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      totalRecords: 0,
      filters: {}
    }
  },
  created() {
    this.columns = [
      { title: 'Customer', key: 'customer', orderable: false },
      { title: 'Business Unit', key: 'businessUnitName', orderable: false },
      { title: 'City, State', key: 'city', orderable: false },
      { title: 'Job type', key: 'category', orderable: false },
      { title: 'Status', key: 'jobStatus', orderable: false },
      { title: 'Completed on', key: 'remoteCompletedAt', orderable: false },
      { title: 'Tenant', key: 'tenant', orderable: false },
      { title: 'Invoice(s) total', key: 'invoicesTotal', orderable: false },
      { title: '', key: 'action', orderable: false }
    ]
  },
  methods: {
    tableSource(prms) {
      this.totalRecords = 0
      const params = {
        ...prms,
        ...this.filters,
        page: this.currentPage
      }

      return axios.get(
        this.baseUrl,
        {
          params,
          paramsSerializer(json) { return qs.stringify(snakeCaseKeys(json), { arrayFormat: 'brackets' }) }
        }
      )
        .then((res) => {
          this.totalRecords = parseInt(res.headers['total-count'], 10)
          return res.data
        })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    reload() {
      this.$refs.table?.refetch()
    },
    applyFilters(filters) {
      this.filters = filters
      this.currentPage = 1
      this.reload()
    }
  }
}
</script>

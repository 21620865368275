<template>
  <div class="geobooster-widgets-edit-form">
    <div class="geobooster-widgets-edit-form__line">
      <p>Embed the following code snippet into your website to display {{ gbName }} moments.</p>
      <RadioPanels
        v-model="platform"
        :values="platformRadioValues"
        name="platform"
        label="Platform" />
    </div>
    <p v-if="platform === 'wordpress'"
      class="geobooster-widgets-edit-form__text">
      Please make sure to exclude the widget script from the scope of the script optimizing/caching plugins,
      e.g. WP Rocket, if you use any.
      These plugins interfere with the widget script and make it useless.
    </p>
    <div class="geobooster-widgets-edit-form__line">
      <div class="geobooster-widgets-edit-form__code">
        <pre ref="code">
          {{ embedCode }}
        </pre>
        <button class="button-copy" @click="onCopy">
          <i class="far fa-fw fa-copy" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'
import RadioPanels from 'vue_widgets/components/radio_panels'

export default {
  components: {
    RadioPanels
  },
  props: {
    configuration: {
      type: Object,
      required: true
    },
    widgetSrc: {
      type: String,
      required: true
    }
  },
  data: () => ({
    platform: 'wordpress',
    platformRadioValues: [
      {
        text: 'Universal',
        value: 'universal'
      },
      {
        text: 'WordPress',
        value: 'wordpress'
      },
      {
        text: 'WIX',
        value: 'wix'
      }
    ]
  }),
  computed: {
    gbName() {
      return Styxie.WLC.gbName
    },
    embedCode() {
      // formatting here is weird because <pre> is weird
      /* eslint-disable no-useless-escape */
      return `\n<script
  id="gb_widget_script"
  type="module"
  src="${this.widgetSrc}"${this.platform === 'wix' ? '\n  data-container-id="gb-widget"' : ''}
  data-widget-token="${this.configuration.widgetToken.trim()}">
<\/script>`
    }
  },
  methods: {
    onCopy(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.$refs.code.innerText.trim() })
    }
  }
}
</script>

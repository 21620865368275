<template>
  <tr :class="{ checked: row.checked }">
    <td>
      <div class="d-flex">
        <Checkbox :value="row.checked" @input="onRowCheckToggle" />
        <div>
          <a :href='row.state === "finished" ? row.url : null' @click.exact.prevent.stop='showGeogrid({ geogrid: row })' class='geogrid-link'>
            {{ row.attrs.businessName }}
          </a>
          <small v-if='row.attrs.businessAddress'>{{ row.attrs.businessAddress }}</small>
          <small v-else class='text-muted'>No address data</small>
          <div v-if='row.attrs.labels' class='location-labels'>
            <span v-for='label in row.labels' :key='`${label}${row.obfuscatedId}`' class='location-labels-item'>
              {{ label }}
            </span>
          </div>
        </div>
      </div>
    </td>
    <td>
      <p v-if="mainCategory" class="text-dark font-weight-bold m-0">{{ mainCategory }};</p>
      <p v-for="( category, categoryIndex ) in secondaryCategories"
        class="m-0"
        :key="categoryIndex">
        {{ category }}{{ categoryIndex < secondaryCategories.length - 1 ? ',' : '' }}
      </p>
    </td>
    <td>
      {{ row.searchTerm }}
    </td>
    <td>
      <span
        v-if="row.state === 'finished' || row.state == 'error'"
        :id='`created-${row.obfuscatedId}`'>
        {{ row.createdAt }}
      </span>
      <div v-else-if="row.state === 'processing' || row.state === 'initialized'">
        <i class='mr-1 far fa-spinner fa-pulse fa-fw' />In progress...
      </div>
    </td>
    <td>
      <GeogridAverageRanks :agr='row.agr' :atgr='row.atgr' :solv='row.solv' />
    </td>
    <td>
      <div class="geogrids-table__preview">
        <GeogridPreview :ranks="row.ranks" :ref="`${row.obfuscatedId}-geogrid-preview`" />
      </div>
      <div v-if="row.state === 'error'" class="badge-danger d-flex align-items-center py-1 px-2 gap-2 rounded">
        <i class="far fa-exclamation-circle" />
        Failed
      </div>
    </td>
    <td class="text-center">
      <div v-if="row.state === 'error'" class="d-flex flex-column align-items-center gap-2">
        <button @click='repeatGeogrid(row)' class="btn btn-link" :id="`repeat-geogrid-${row.obfuscatedId}`">
          <i class="far fa-repeat" />
        </button>
        <b-popover
          placement='left'
          :target="`repeat-geogrid-${row.obfuscatedId}`"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          Repeat geogrid
        </b-popover>
        <button @click='deleteGeogrid(row.obfuscatedId)' class="btn btn-link btn-danger" :id="`delete-geogrid-${row.obfuscatedId}`">
          <i class="far fa-trash" />
        </button>
        <b-popover
          placement='left'
          :target="`delete-geogrid-${row.obfuscatedId}`"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          <span class='text-danger'>Delete geogrid</span>
        </b-popover>
      </div>
      <DropdownActions
        v-else-if="row.state === 'finished'"
        :ref='`dropdownActions-${row.obfuscatedId}`'
        :geogrid='row'
        :geogrids-path='geogridsPath'
        :is-scheduled='false'
        :is-on-index='true' />
    </td>
  </tr>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import Checkbox from '../../../components/checkbox'
import GeogridPreview from '../../components/geogrid_preview'
import GeogridAverageRanks from '../../components/geogrid_average_ranks'
import DropdownActions from '../components/dropdown_actions'

export default {
  components: {
    GeogridPreview, GeogridAverageRanks, DropdownActions, Checkbox
  },
  props: {
    row: { type: Object, required: true },
    geogridsPath: { type: String, required: true }
  },
  methods: {
    ...mapActions(['showGeogrid', 'deleteGeogrid']),
    ...mapMutations(['repeatGeogrid']),
    addTagToFilter(tag) {
      this.$emit('addTagToFilter', tag)
    },
    onRowCheckToggle(bool) {
      this.$emit('onRowCheckToggle', { bool, configId: this.row.obfuscatedId })
    }
  },
  computed: {
    businessCategories() {
      return this.row.attrs?.businessCategories ?? []
    },
    mainCategory() {
      return this.businessCategories[0]
    },
    secondaryCategories() {
      return this.businessCategories.slice(1)
    }
  }
}
</script>

<template>
  <tr>
    <td class="rank-cell" column-caption="Rank:">
      <div class="rank-name">
        <i class="color-icon" :style="{backgroundColor: rankColor}" />
        {{ rankName }}
        <a v-if="serpHtml" :href="serpHtml" target="_blank">
          <i class="far fa-cloud-download font-size-12" />
        </a>
      </div>
    </td>
    <template v-if="keyword[rankType]">
      <td class="position-cell" column-caption="Current Position:">
        <div>
          {{ currentPossition }}
          <span v-if="getDiff(rank) !== null" :class="['delta', differenceClass(getDiff(rank))]">{{ Math.abs(getDiff(rank)) }}</span>
        </div>
      </td>
      <td class="position-cell" column-caption="The Best Position:">{{ bestPosition }}</td>
      <td class="position-cell" column-caption="Total Checks:">{{ keyword[`${rankType}TotalChecks`] }}</td>
      <td column-caption="Last Checked:">{{ syncedAt }}</td>
      <td column-caption="Rank Updated At:">{{ latestRankUpdatedAt }}</td>
      <td>
        <a
          href="#"
          :class="['btn btn-link btn-danger', { disabled: isEditingDisabled }]"
          @click.prevent="deleteRank">
          Delete
        </a>
      </td>
    </template>
    <td v-else colspan="5">
      <div class="d-flex align-items-center gap-2 w-100">
        <button
          :disabled="rankTableDisable || isEditingDisabled"
          @click="addKeywordRank(rankType)"
          type="button"
          class="btn btn-sm with-icon rounded gap-2">
          <template v-if="btnLocked">
            <i class="far fa-circle-notch fa-spin m-0" aria-hidden="true" />
            <span>Adding</span>
          </template>
          <template v-else>
            <i class="far fa-plus-circle m-0" aria-hidden="true" />
            <span>Add</span>
          </template>
        </button>
        <span class="text-muted">
          We'll start checking it today
        </span>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { names, colors, getDiff } from './helpers'
import { differenceClass } from '../../../common/helpers'
import { DATE_FORMATS } from '../../../helpers/formatters'

export default {
  props: {
    rankType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      btnLocked: false
    }
  },
  created() {
    this.rank = this.keyword.data && this.keyword.data[this.rankType]
    this.isEditingDisabled = !Styxie?.USER?.editor
  },
  computed: {
    ...mapGetters(['keyword', 'rankTableDisable']),
    bestPosition() {
      if (this.rank) {
        const bestRank = this.rank.map((pos) => pos.y).sort()[0]
        if (!bestRank || bestRank >= 100) {
          return 'N/A'
        }
        return this.rank.map((pos) => pos.y).sort()[0]
      }
      return 'N/A'
    },
    currentPossition() {
      if (!this.rank) return 'N/A'
      if (!this.rank.length || !this.rank[this.rank.length - 1].y || this.rank[this.rank.length - 1].y >= 100) return 'N/A'
      return this.rank[this.rank.length - 1].y
    },
    syncedAt() {
      // Even if there is this.keyword.syncedAt but this.keyword[`${this.rankType}CheckedAt`] is zero it means that
      // this particular keyword type is not checked yet
      if (!this.keyword[`${this.rankType}TotalChecks`]) return 'Never'
      return moment.parseZone(this.keyword.syncedAt).format(DATE_FORMATS.full)
    },
    latestRankUpdatedAt() {
      if (!this.keyword[`${this.rankType}TotalChecks`] || !this.keyword.latestRankUpdatedAt) return '-'
      return moment.parseZone(this.keyword.latestRankUpdatedAt).format(DATE_FORMATS.full)
    },
    rankName() {
      return names[this.rankType]
    },
    rankColor() {
      return colors[this.rankType]
    },
    serpHtml() {
      return this.keyword[`${this.rankType}SerpHtml`]
    }
  },
  methods: {
    ...mapActions(['deleteKeyword', 'addRank']),
    deleteRank() {
      const type = this.rankType === 'organicMobile' ? 'organic_mobile' : this.rankType
      this.deleteKeyword([type])
    },
    async addKeywordRank(type) {
      this.btnLocked = true
      await this.addRank(type)
      this.btnLocked = false
    },
    getDiff,
    differenceClass
  }
}
</script>

import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import BlocklistEdit from 'vue_widgets/jensen/blocklist/edit.vue'

Styxie.Initializers.JensenBlocklists = {
  edit: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-blocklist-edit'),
        render: (h) => h(BlocklistEdit, { props: camelCaseKeys(params) })
      })
    })
  }
}

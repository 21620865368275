<template>
  <div class="mv-color-picker">
    <div class="mv-color-picker__wrapper">
      <CustomInput
        class="mv-color-picker__input"
        :value="value"
        :label="label"
        :error="error"
        @blur="handleInput($event.target.value)" />
      <div
        ref="pickerWrapper"
        class="mv-color-picker__picker-wrapper">
        <div
          class="mv-color-picker__preview"
          :style="computedPreviewStyle"
          @click="showPicker = !showPicker" />
        <chrome-picker
          ref="pickerComponent"
          class="mv-color-picker__picker"
          :style="customPickerCss"
          :class="{ 'mv-active' : showPicker, 'mv-color-picker__picker--invert-position': invertPickerPosition }"
          :value="value"
          :disable-alpha="true"
          @input="handleInput($event.hex)" />
      </div>
    </div>
    <div v-if="error" class="mv-color-picker__error text-danger">{{ errorText }}</div>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
import CustomInput from './custom_input'

export default {
  components: {
    'chrome-picker': Chrome,
    CustomInput
  },
  props: {
    value: {
      type: String,
      required: true
    },
    defaultValue: {
      type: String,
      required: false,
      default: '#ffffff'
    },
    label: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    errorText: {
      type: String,
      required: false
    },
    customPickerCss: {
      type: String,
      required: false
    }
  },
  data: () => ({
    showPicker: false,
    invertPickerPosition: false
  }),
  methods: {
    closeColorPicker(e) {
      if (!e || !e.composedPath()) {
        return
      }

      const path = e.composedPath()

      const pathArray = Array.from(path)

      if (!pathArray.includes(this.$refs.pickerWrapper)) {
        this.showPicker = false
      }
    },
    handleInput(newValue) {
      if (/^#([0-9a-f]{3}){1,2}$/i.test(newValue)) {
        this.$emit('input', newValue)
        this.$emit('setError', { error: false, errorText: '' })
      } else {
        this.$emit('input', this.defaultValue)
        this.$emit('setError', { error: true, errorText: 'Please, specify a valid hex color' })
      }
    },
    setInvertPosition() {
      this.invertPickerPosition = false

      this.$nextTick(() => {
        const rect = this.$refs.pickerComponent.$el.getBoundingClientRect()

        this.invertPickerPosition = rect.top - rect.height < 0
      })
    }
  },
  computed: {
    isPreviewWhite() {
      return this.value.toLowerCase() === '#ffffff' ||
        this.value.toLowerCase() === '#fff' ||
        !this.value
    },
    computedPreviewStyle() {
      return {
        backgroundColor: this.value ? this.value : '#ffffff'
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeColorPicker)
  },
  destroyed() {
    document.removeEventListener('click', this.closeColorPicker)
  },
  watch: {
    showPicker() {
      if (this.showPicker) {
        this.setInvertPosition()
      }
    }
  }
}
</script>

<template>
  <div class="moments-dashboard">
    <DashboardFilter
      v-model="filters"
      class="w-100" />
    <div class="d-flex align-items-end justify-content-between pt-4">
      <CustomSelect
        class="w-20"
        v-model="daysCovered"
        label="Highlight gaps"
        :values="daysCoveredOptions" />

      <div>
        <div class="datepicker-wrapper">
          <span class="range-output">
            {{ firstLastDateString }}
          </span>
          <DatePicker
            class="inline w-100"
            :date-format="false"
            :value="currentDate"
            @input="changeDate" />
        </div>

        <button class="btn btn-link" @click="setInterval(1)" :disabled="waitingResponse">
          <i class="fa-solid fa-chevron-left" />
        </button>
        <button class="btn btn-link ml-1" @click="setInterval(-1)" :disabled="waitingResponse">
          <i class="fa-solid fa-chevron-right" />
        </button>
      </div>
    </div>
    <VueSlimTable
      ref="table"
      :source="getBusinesses"
      :columns="columns"
      :per-page="10"
      class="table table-fixed mv-businesses-table mv-businesses-table--dashboard">
      <template v-for="column in columns" #[`head:${column.key}`]>
        <span class="text-break-after-each text-dark align-bottom" :key="column.key">
          {{ column.title }}
        </span>
      </template>
      <template #row="{ row }">
        <tr class="text-dark">
          <td class="moments-dashboard__cell">
            <div class="d-flex flex-column justify-items-center justify-content-between w-full gap-2">
              <template v-if="row.disconnected">
                <i :id="row.id" class="color-warning far fa-exclamation-circle" />
                <BTooltip :target="row.id" title="Location is disconnected" />
              </template>
              <a :href="getBusinessLink(row.business.id)">{{ row.business.name }}</a>
              <span v-if="row.business.storeCode"
                class="moments-dashboard__store-code">
                {{ row.business.storeCode }}
              </span>
              <span
                class="moments-dashboard__address">
                {{ row.business.address ?? 'Service Area Business' }}
              </span>
            </div>
          </td>
          <td class="align-top moments-dashboard__cell"
            :class="{ 'text-disabled': row.draftMoments === 0 }"
            @click="row.draftMoments > 0 ? openSidebar({ $event, date: null, business: row.business, showDrafts: true }) : null">
            <span class="moments-dashboard__tag">
              {{ row.draftMoments }}
            </span>
          </td>
          <td class="align-top moments-dashboard__cell">
            <span class="moments-dashboard__tag">
              {{ row.daysWithoutPublishedCheckins ?? 'N/A' }}
            </span>
          </td>
          <td
            v-for="({ key, date, moments, notCovered, stJobs, itemsCount }) in formattedCells(columns.slice(3), row)"
            class="align-top moments-dashboard__cell"
            :key="key"
            :class="{
              'text-primary cursor-pointer': itemsCount > 0,
              'text-disabled': itemsCount === 0,
              'disabled': isEditingDisabled }"
            @click="itemsCount > 0 ? openSidebar({ $event, date, business: row.business }) : null">
            <span
              class="moments-dashboard__tag"
              :class="{
                'moments-dashboard__tag--danger': notCovered,
                'moments-dashboard__tag--empty': itemsCount === 0 && (currentDate >= todayDate),
                'moments-dashboard__tag--primary-hover': itemsCount > 0,
                'moments-dashboard__tag--multiple': moments > 1 && (currentDate >= todayDate)
              }">
              {{ moments }}

              <div v-if="stEnabled && stJobs" class="st-job">
                <i class="far fa-fw fa-wrench" />{{ stJobs }}
              </div>
            </span>

            <a v-if="itemsCount === 0 && (currentDate >= todayDate)" class="moments-dashboard__add btn btn-primary btn-xs rounded"
              :href="newMomentLink(row.business, date)"
              target="_blank">
              <i class="far fa-circle-plus fa-fw" />
            </a>
          </td>
        </tr>
      </template>
      <template #pagination>
        <Pagination class="pb-0"
          :page="currentPage"
          :per-page="perPage"
          :total-count="totalBusinessesCount"
          @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
    <DashboardSidebar
      v-if="sidebar.active"
      :active="sidebar.active"
      :params="sidebar.params"
      :business-name="sidebar.businessName"
      :date="sidebar.params.byPublishingDate[0]"
      :new-moment-link="sidebar.newMomentLink"
      :st-enabled="stEnabled"
      :st-job-generating-id="stJobGeneratingId"
      @close="closeSidebar"
      @convertJob2Moment="convertJob2Moment" />
  </div>
</template>

<script>
import snakecaseKeys from 'snakecase-keys'
import toastr from 'toastr'

import Pagination from 'vue_widgets/components/pagination'
import CustomSelect from 'vue_widgets/components/custom_select'
import DatePicker from 'vue_widgets/components/datepicker'
import axiosTransform from 'common/axios'
import { App } from 'common/rails'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import DashboardSidebar from './sidebar'
import DashboardFilter from './filter'

const TIMELINE_DAYS = 8
const DEFAULT_DAYS_COVERED = 7
const DAYS_COVERED_OPTIONS = Array(11).fill(0).map((_, index) => ({
  value: index,
  text: `${index} ${(index === 1) ? 'day' : 'days'} without posting`
}))

export default {
  components: {
    Pagination,
    DatePicker,
    DashboardFilter,
    DashboardSidebar,
    CustomSelect
  },
  props: {
    stEnabled: { type: Boolean, default: false }
  },
  data() {
    return {
      daysCovered: DEFAULT_DAYS_COVERED,
      daysCoveredOptions: DAYS_COVERED_OPTIONS,
      currentPage: 1,
      totalBusinessesCount: 0,
      perPage: 20,
      todayDate: new Date(),
      currentDate: new Date(),
      sidebar: {
        active: false,
        params: {},
        businessName: '',
        newMomentLink: null
      },
      filters: {
        search: ''
      },
      firstLastDateString: '',
      columns: [],
      waitingResponse: true,
      timeout: null,
      stJobGeneratingId: null
    }
  },
  methods: {
    changeDate([date]) {
      this.waitingResponse = true
      this.currentDate = date
      this.$refs.table.reload()
    },
    setInterval(direction) {
      this.waitingResponse = true
      this.currentDate.setDate(this.currentDate.getDate() - direction * TIMELINE_DAYS)
      this.$refs.table.reload()
    },
    newMomentLink(business, date) {
      const params = {
        businessId: business.id
      }

      if (date) {
        params.date = date
      }

      return `/gb/moments/new?${qs.stringify(snakecaseKeys(params))}`
    },
    getBusinessLink(id) {
      return `businesses/${id}`
    },
    getBusinesses(prms = {}) {
      return axios.get(
        '/gb/ajax/businesses/dashboard',
        {
          params: {
            order: prms.order,
            ...this.backendFilters,
            startDate: this.currentDate.toDateString(),
            perPage: this.perPage,
            page: this.currentPage
          },
          paramsSerializer(json) { return qs.stringify(snakecaseKeys(json, { deep: true }), { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }
      ).then((res) => {
        this.columns = res.data.columns
        this.firstLastDateString = [3, -1].map((index) => this.columns.at(index).title).join(' - ')
        this.totalBusinessesCount = parseInt(res.headers['total-count'], 10)
        return res.data.data
      }).finally(() => {
        this.waitingResponse = false
      })
    },
    formattedCells(dateColumns, row) {
      const cells = []

      let noPostingDays = row.daysWithoutPublishedCheckins ?? 0

      dateColumns.forEach(({ key }) => {
        const cell = {
          date: key,
          key: `${row.business.id}${key}`,
          moments: row[key].moments,
          stJobs: row[key].stJobs || 0,
          itemsCount: row[key].moments + (row[key].stJobs || 0)
        }

        if (cell.moments) {
          noPostingDays = 0
        } else {
          noPostingDays++
        }

        if (noPostingDays > this.daysCovered) {
          cell.notCovered = true
        }

        cells.push(cell)
      })

      return cells
    },
    openSidebar({
      $event, date, business, showDrafts = false
    }) {
      let tabIndex = 0
      if ($event.target.closest('.st-job')) tabIndex += 1

      const params = {
        business: business.id,
        serviceTitanBusinessUnit: business.serviceTitanBusinessUnitId,
        tabIndex
      }

      if (!showDrafts) {
        params.byPublishingDate = [date, date]
      } else {
        params.statuses = ['pending']
      }

      this.sidebar.active = true
      this.sidebar.params = params
      this.sidebar.businessName = business.name
      this.sidebar.newMomentLink = this.newMomentLink(business, date)
    },
    closeSidebar() {
      this.sidebar.active = false
      this.sidebar.params = {}
      this.sidebar.businessName = ''
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.$refs.table.reload()
    },
    convertJob2Moment(jobId) {
      axios.post(`/gb/ajax/service_titan/jobs/${jobId}/create_checkin`)
        .then(() => {
          this.stJobGeneratingId = jobId
          toastr.success('Moment creation started!')
        })
    }
  },
  computed: {
    backendFilters() {
      // eslint-disable-next-line no-unused-vars
      const { ...filters } = this.filters
      return filters
    }
  },
  created() {
    const savedDaysCovered = localStorage.getItem('savedDaysCovered')
    if (savedDaysCovered) {
      this.daysCovered = parseInt(savedDaysCovered, 10)
    }
    this.isEditingDisabled = !Styxie?.USER?.editor

    this.cable = App.cable.subscriptions.create(
      { channel: 'ServiceTitan::CreateCheckinChannel' },
      {
        received: ({ id, failed }) => {
          if (failed) {
            this.stJobGeneratingId = null
            toastr.error(DEFAULT_ERROR_MESSAGE)
            return
          }

          Turbolinks.visit(`/gb/moments/${id}/edit`)
        }
      }
    )
    $(document).one('turbolinks:visit', () => {
      App.cable.subscriptions.remove(this.cable)
    })
  },
  watch: {
    backendFilters: {
      handler(old, newv) {
        if (JSON.stringify(old) === JSON.stringify(newv)) return
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$refs.table.reload()
        }, 500)
      },
      deep: true
    },
    daysCovered() {
      localStorage.setItem('savedDaysCovered', this.daysCovered)
    }
  }
}
</script>

export const STATUSES_ICONS = {
  answered: 'fa-solid fa-circle-check',
  voicemail: 'fa-regular fa-voicemail',
  missed: 'fa-solid fa-phone-missed',
  canceled: 'fa-regular fa-xmark',
  spam: 'fa-regular fa-octagon-exclamation'
}

export const STATUSES_COLORS = {
  answered: 'green',
  voicemail: 'blue',
  missed: 'red',
  canceled: 'red',
  spam: 'yellow'
}

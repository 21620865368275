<template>
  <div class="new-phone-number">
    <div v-if="!validTelephonyAccountsPresent" class="new-phone-number__alert alert alert-warning">
      <p>You have no valid telephony accounts connected.</p>
      <p>Please, <a href="/call_tracking/telephony_accounts/new">Add new telephony account</a></p>
    </div>
    <div class="new-phone-number__wrapper" :class="{ disabled: !validTelephonyAccountsPresent }">
      <div class="new-phone-number__left">
        <div v-for="(input, inputName) in inputs"
          :key="inputName"
          class="new-phone-number__line">
          <component
            v-model="input.value"
            v-show="!input.hidden"
            :is="input.component"
            :values="input.values"
            :label="input.label"
            :allow-empty="input.allowEmpty"
            :disabled="input.disabled || !validTelephonyAccountsPresent"
            v-bind="input.attrs"
            class="new-phone-number__input" />
        </div>
      </div>
      <div class="new-phone-number__right">
        <div
          class="new-phone-number__loader"
          :class="{ active: loading }">
          <Spinner />
        </div>
        <div class="new-phone-number__top">
          <h3 class="new-phone-number__title">
            Available Phone Numbers
          </h3>
          <span class="new-phone-number__subtitle">
            Please select country and area code to look at available phone numbers.
          </span>
        </div>
        <div class="new-phone-number__list">
          <template v-if="phoneNumbers.length">
            <div
              v-for="({ number, locality, region }, index) in phoneNumbers"
              :key="number"
              class="new-phone-number__item"
              :class="{ active: selectedPhoneNumber?.number === number }"
              @click="selectPhoneNumber(index)">
              <p>{{ number }}</p>
              <span> {{ formatLocation(locality, region) }} </span>
            </div>
          </template>
          <template v-else>
            <div class="new-phone-number__empty">
              <i class="fa-regular fa-circle-info" />
              <span class="pl-2">
                No phone numbers found for your criteria
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="selectedPhoneNumber"
      class="new-phone-number__bottom">
      <div class="new-phone-number__info">
        Selected number:
        <b>{{ selectedPhoneNumber.number }}</b>
        from region
        <b>{{ formatLocation(selectedPhoneNumber.locality, selectedPhoneNumber.region) }}</b>.
      </div>
      <button
        class="new-phone-number__button btn btn-primary btn-sm"
        @click="purchaseNumber">
        Add this phone number
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'
import CustomSelect from 'vue_widgets/components/custom_select'
import CustomInput from 'vue_widgets/components/custom_input'
import Spinner from 'vue_widgets/components/spinner'

export default {
  components: {
    CustomSelect,
    CustomInput,
    Spinner
  },
  props: {
    accounts: {
      type: Array,
      required: true
    },
    countries: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      selectedPhoneNumber: null,
      phoneNumbers: [],
      inputs: {
        account: {
          label: 'Telephony Account',
          value: this.accounts[0]?.value || {},
          values: this.accounts,
          component: 'CustomSelect'
        },
        country: {
          label: 'Country',
          value: '',
          values: this.countries,
          component: 'CustomSelect',
          allowEmpty: true,
          hidden: false
        },
        areaCode: {
          label: 'Area code',
          value: '',
          component: 'CustomInput',
          disabled: false,
          attrs: {
            type: 'number'
          }
        }
      }
    }
  },
  methods: {
    fetchPhoneNumbers() {
      if (!this.validTelephonyAccountsPresent) return
      this.loading = true

      axios.get(
        '/call_tracking/ajax/phone_numbers/search',
        {
          params: this.filters,
          paramsSerializer(json) { return qs.stringify(snakecaseKeys(json), { arrayFormat: 'brackets' }) }
        }
      )
        .then(({ data }) => {
          this.phoneNumbers = data
        })
        .finally(() => {
          this.loading = false
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toastr.error(error.response.data.text)
          }
        })
    },
    purchaseNumber() {
      return axios.post(
        '/call_tracking/ajax/phone_numbers',
        {
          account: this.filters.account,
          number: this.selectedPhoneNumber.number
        }
      )
        .then(() => {
          $(document).one('turbolinks:load', () => {
            toastr.success('Number added succesfully')
          })
          Turbolinks.visit('/call_tracking/phone_numbers')
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toastr.error(error.response.data.text)
          }
        })
    },
    formatLocation(locality, region) {
      return [locality, region]
        .filter((part) => part)
        .join(', ')
    },
    selectPhoneNumber(numberIndex) {
      this.selectedPhoneNumber = this.phoneNumbers[numberIndex]
    }
  },
  computed: {
    filters() {
      const computedFilters = {}

      Object.keys(this.inputs).forEach((inputName) => {
        const inputValue = this.inputs[inputName].value
        computedFilters[inputName] = inputValue
      })

      return computedFilters
    },
    selectedAccountProvider() {
      if (!this.validTelephonyAccountsPresent) return null
      const selectedValue = this.inputs.account.value
      const { values } = this.inputs.account
      const { provider } = values.find(({ value }) => value === selectedValue)
      return provider
    },
    selectedCountry() {
      return this.inputs.country.value
    },
    validTelephonyAccountsPresent() {
      return this.accounts.length > 0
    }
  },
  watch: {
    filters: {
      handler() {
        this.selectedPhoneNumber = null

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.fetchPhoneNumbers()
        }, 500)
      },
      deep: true
    },
    selectedAccountProvider: {
      handler(newProvider) {
        const isSignalwire = newProvider === 'signalwire'
        this.inputs.country.hidden = isSignalwire
        if (!isSignalwire) return
        this.inputs.country.value = this.inputs.country.values[0].value
      },
      immediate: true
    },
    selectedCountry: {
      handler(newVal) {
        this.inputs.areaCode.disabled = !newVal
        this.inputs.areaCode.value = ''
      },
      immediate: true
    }
  }
}
</script>

<template>
  <div>
    <LocationSelect
      class="w-57 mb-4"
      v-model="filters.location"
      :locations="locations"
      @input="filters.location = $event" />
    <div class="d-flex align-items-end gap-6">
      <CustomSelect
        v-model="filters.rating"
        class="w-35"
        label="Rating"
        placeholder="All"
        :allow-empty="true"
        :values="ratingOptions" />
      <div class="form-field form-field--rem form-field--grid w-35">
        <span class="m-0">{{ DatePickerLabel }}</span>
        <Datepicker
          mode="range"
          :max-date="maxDate"
          :min-date="minDate"
          placeholder="All"
          date-format="d/m/Y"
          v-model="filters.range" />
      </div>
      <CustomSelect
        v-model="filters.response"
        class="w-35"
        label="Response"
        placeholder="Any"
        :allow-empty="true"
        :values="responseOptions" />
      <button class="btn btn-pretender px-0 border-0 gap-2 w-15"
        :class="{ 'text-muted': !anyFiltersSelected }"
        :disabled="!anyFiltersSelected"
        @click="clearFilters">
        <i class="far fa-eraser" aria-hidden="true" />
        Clear filters
      </button>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import CustomSelect from 'vue_widgets/components/custom_select'
import LocationSelect from './location_select'
import Datepicker from '../components/datepicker.vue'

export default {
  components: {
    CustomSelect,
    LocationSelect,
    Datepicker
  },
  props: {
    value: { type: Object, required: true },
    locations: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    filters: {
      response: '',
      rating: '',
      location: '',
      range: []
    },
    DatePickerLabel: 'Date Range'
  }),
  created() {
    const ALL = { text: 'All', value: '' }
    this.ratingOptions = [
      ALL,
      { text: '5 star only', value: 5 },
      { text: '4 star only', value: 4 },
      { text: '3 star only', value: 3 },
      { text: '2 star only', value: 2 },
      { text: '1 star only', value: 1 }
    ]
    this.responseOptions = [
      ALL,
      { text: 'With response', value: 'true' },
      { text: 'No response', value: 'false' }
    ]
    this.minDate = moment.utc('2007-01-01').toDate()
  },
  computed: {
    anyFiltersSelected() {
      return Object.keys(this.filters).find((filterName) => this.filters[filterName])
    },
    maxDate() {
      return moment.utc().toDate()
    }
  },
  methods: {
    clearFilters() {
      this.filters = Object.keys(this.filters).reduce((mem, key) => {
        if (key === 'range') {
          mem[key] = []
        } else {
          mem[key] = ''
        }
        return mem
      }, {})
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('input', this.filters)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<template>
  <div class="call-tracking-instruction">
    <div class="call-tracking-instruction__steps">
      <div
        v-for="({title, text, complete}, index) in steps"
        class="call-tracking-instruction__item"
        :class="{ active: complete }"
        :key="index">
        <div class="call-tracking-instruction__index">{{ index + 1 }}</div>
        <p>{{ title }}</p>
        <span>{{ text }}</span>
      </div>
    </div>
    <div v-if="link" class="call-tracking-instruction__buttons">
      <a class="btn btn-primary" :href="link.href">
        <span class="pr-2">{{ link.text }}</span>
        <i :class="link.icon" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLocationPage: { type: Boolean, default: false },
    instructionStepInfo: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      steps: [
        {
          title: 'Connect a Telephony Account',
          text: 'Go to the \'Telephony Account\' section, click \'Add Telephony account\' and select Twilio or SignalWire to enable call tracking',
          complete: false
        },
        {
          title: 'Wait for Sync Completion',
          text: 'Syncing may take a few minutes. Once complete, the ‘Telephony Account’ page will display an ‘Active’ status',
          complete: false
        },
        {
          title: 'Purchase a Phone Number',
          text: 'If you don’t have any phone numbers linked to your Telephony account, purchase one',
          complete: false
        },
        {
          title: 'Link a location',
          text: 'Select a phone number, open the number configuration page, and connect it to a location',
          complete: false
        }
      ]
    }
  },
  created() {
    this.setStepsComplete()
  },
  methods: {
    setStepsComplete() {
      if (!this.instructionStepInfo) return

      const {
        hasTelephonyAccount,
        hasSyncedTelephonyAccount,
        hasAtLeastOneNumberPurchased
      } = this.instructionStepInfo

      const initialSteps = [
        hasTelephonyAccount,
        hasSyncedTelephonyAccount,
        hasAtLeastOneNumberPurchased
      ]

      initialSteps.forEach((step, index) => {
        this.steps[index].complete = step
      })
    }
  },
  computed: {
    link() {
      if (!this.isLocationPage) return false

      return {
        icon: 'fa-regular fa-arrow-right',
        text: 'Proceed to Call Tracking',
        href: '/call_tracking/phone_numbers'
      }
    }
  }
}
</script>

function initForm(prms) {
  $(() => {
    const PER_PAGE = 10

    const $locationsSelect = $('#user_whitelisted_orglocation_ids')
    $locationsSelect.turboSelect({
      width: '100%',
      dropdownAutoWidth: 'true',
      multiple: true,
      ajax: {
        url: prms.allOrgLocsPath,
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            search: params.term,
            page: params.page || 1,
            per_page: PER_PAGE
          }
        },
        processResults(results) {
          return {
            results: results,
            pagination: { more: results.length === PER_PAGE }
          }
        }
      }
    })

    const $tagsSelect = $('#user_whitelisted_location_tags')
    $tagsSelect.turboSelect({
      width: '100%',
      dropdownAutoWidth: 'true',
      multiple: true,
      ajax: {
        url: prms.tagsPath,
        dataType: 'json',
        delay: 250,
        data(params) {
          return {
            search: params.term,
            page: params.page || 1
          }
        },
        processResults(results) {
          return {
            results: results.map((el) => ({ id: el, text: el })),
            pagination: { more: results.length === PER_PAGE }
          }
        }
      }
    })

    const $gbModeration = $('#user_gb_moderation_required')

    const $roleRadio = $('input:radio[name="user[role]"]')
    $roleRadio.change((e) => {
      const isMember = e.currentTarget.value === 'member' || e.currentTarget.value === 'viewer'
      $tagsSelect.prop('disabled', !isMember)
      $locationsSelect.prop('disabled', !isMember)

      const cantBeModerated = e.currentTarget.value === 'viewer' || e.currentTarget.value === 'admin'
      $gbModeration.prop('disabled', cantBeModerated)
      if (cantBeModerated) {
        $gbModeration.prop('checked', false)
      }
    })
    $roleRadio.filter(':checked').change()
  })
}

Styxie.Initializers.Users = {
  new: (params) => { initForm(params) },
  create: (params) => { initForm(params) },
  edit: (params) => { initForm(params) },
  update: (params) => { initForm(params) }
}

<template>
  <div>
    <form action="/" @submit.prevent="onSubmit" class="p-3">
      <h3>{{ tenant.id ? 'Edit' : 'Create' }} Tenant</h3>

      <div v-for="input in INPUTS" :key="input.key" class="form-group">
        <label :for="`tenant-${input.name}`">{{ input.label }}</label>
        <input
          v-once
          type="text"
          class="form-control"
          :id="`tenant-${input.name}`"
          :name="`tenant[${input.name}]`"
          :placeholder="`Enter ${input.label}`"
          :value="tenant[input.key]">

        <small v-if="validationErrors[input.name]" class="text-danger">
          {{ validationErrors[input.name].join(', ') }}
        </small>
      </div>

      <button type="submit" class="btn btn-primary">
        {{ tenant.id ? 'Update' : 'Create' }}
      </button>

      <button type="button" class="btn btn-link ml-3" @click="$emit('form-cancel')">
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    tenant: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      validationErrors: {}
    }
  },
  methods: {
    onSubmit(e) {
      const data = new FormData(e.target)
      this.validationErrors = {}

      if (this.tenant.id) {
        this.submitTenant(axios.put(`/api/service_titan/tenants/${this.tenant.id}`, data))
      } else {
        this.submitTenant(axios.post('/api/service_titan/tenants', data))
      }
    },
    submitTenant(promise) {
      promise
        .then(() => this.$emit('form-submit-success'))
        .catch((err) => {
          if (err.response.status === 422 && err.response.data.type === 'validation') {
            this.validationErrors = err.response.data.text
          } else {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
    }
  },
  created() {
    this.INPUTS = [
      { key: 'title', label: 'Title', name: 'title' },
      { key: 'remoteId', label: 'Tenant ID', name: 'remote_id' },
      { key: 'clientId', label: 'Client ID', name: 'client_id' },
      { key: 'clientSecret', label: 'Client Secret', name: 'client_secret' }
    ]
  }
}
</script>

<template>
  <div class="incoming-calls-table">
    <VueSlimTable
      v-if="!hideTable"
      ref="table"
      :source="getIncomingCalls"
      :columns="columns"
      class="responsive-table borderless-table m-0 incoming-calls-table__item">
      <template #row="{ row }">
        <tr>
          <td>
            <a :href="`/call_tracking/incoming_calls/${row.obfuscatedId}`" :title="row.displayName"
              class="incoming-calls-table__link text-truncate">
              {{ row.displayName }}
            </a>
          </td>

          <td>
            {{ row.received }}
          </td>

          <td>
            <span class="incoming-calls-table__number">
              {{ row.from }}
            </span>
            <span v-if="row.fromAddress" class="incoming-calls-table__address">
              {{ row.fromAddress }}
            </span>
          </td>

          <td>
            <span class="incoming-calls-table__number">
              {{ row.phoneNumber }}
            </span>
          </td>

          <td>
            <div v-if="row.locationId" class="d-flex flex-column justify-items-center justify-content-between w-full gap-1">
              <a class="incoming-calls-table__location-link"
                :href="`/locations/${row.locationId}`">
                {{ row.locationName }}
              </a>
              <span v-if="row.locationStoreCode"
                class="incoming-calls-table__location-store-code">
                {{ row.locationStoreCode }}
              </span>
              <span
                class="incoming-calls-table__location-address">
                {{ row.locationAddress ?? 'Service Area Business' }}
              </span>
            </div>
            <span v-else>-</span>
          </td>

          <td class="incoming-calls-table__recording">
            <div v-if="!row.recordingUrl"
              class="p-2 pl-8 incoming-calls-table__no-recording">
              No recording
            </div>
            <button
              v-else-if="!fetchedRecordings[row.obfuscatedId]"
              class="incoming-calls-table__button"
              @click="fetchRecordingWithAuthorization(row.recordingUrl, row.recordingKey, row.obfuscatedId)">
              <i v-if="!loadingRecordings.includes(row.obfuscatedId)" class="fa-regular fa-play" />
              <i v-else class="far fa-spin fa-spinner" />
            </button>
            <audio
              v-else
              :src="fetchedRecordings[row.obfuscatedId]"
              controls />
          </td>

          <td>
            <div class="incoming-calls-table__statuses">
              <b-popover placement="left" :target="`call-status-${row.obfuscatedId}`"
                custom-class="geogrids-popover p-1" triggers="hover">
                {{ row.callStatusDetails }}
              </b-popover>
              <div
                class="incoming-calls-table__status"
                :class="`incoming-calls-table__status--${STATUSES_COLORS[row.callStatus]}`"
                :id="`call-status-${row.obfuscatedId}`">
                <i :class="STATUSES_ICONS[row.callStatus]" />
                {{ humanize(row.callStatus || 'Failed') }}
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template #pagination>
        <Pagination class="pb-0" :page="currentPage" :per-page="perPage"
          :total-count="totalCount"
          @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>
<script>
import snakecaseKeys from 'snakecase-keys'

import { humanize } from 'common/helpers'
import axiosTransform from 'common/axios'

import Pagination from 'vue_widgets/components/pagination'

import { STATUSES_ICONS, STATUSES_COLORS } from '../helpers'

export default {
  components: {
    Pagination
  },
  props: {
    filters: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalCount: 0,
      hideTable: true,
      fetchedRecordings: {},
      loadingRecordings: []
    }
  },
  created() {
    this.columns = [
      { key: 'displayName', title: 'Name' },
      { key: 'received', title: 'Received' },
      { key: 'from', title: 'From' },
      { key: 'to', title: 'To' },
      { key: 'location', title: 'Location' },
      { key: 'recordingUrl', title: 'Recording/Voicemail' },
      { key: 'callStatus', title: 'Status' }
    ]

    this.searchTimeout = null

    this.STATUSES_ICONS = STATUSES_ICONS
    this.STATUSES_COLORS = STATUSES_COLORS
  },
  methods: {
    getIncomingCalls() {
      return axios.get(
        '/call_tracking/ajax/incoming_calls',
        {
          params: {
            page: this.currentPage,
            ...this.filters
          },
          paramsSerializer(json) { return qs.stringify(snakecaseKeys(json), { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }
      ).then(async(res) => {
        this.totalCount = parseInt(res.headers['total-count'], 10)

        return res.data
      })
    },
    fetchRecordingWithAuthorization(recordingUrl, recordingKey, obfuscatedId) {
      if (!recordingKey && recordingUrl) {
        this.fetchedRecordings[obfuscatedId] = recordingUrl
        this.fetchedRecordings = {
          ...this.fetchedRecordings,
          [obfuscatedId]: recordingUrl
        }
        return
      } if (!recordingUrl) {
        return
      }

      this.loadingRecordings.push(obfuscatedId)

      fetch(recordingUrl, {
        headers: {
          Authorization: `Basic ${recordingKey}`
        }
      })
        .then((res) => {
          if (!res.ok) return null
          return res.blob()
        })
        .then((audioBlob) => {
          this.fetchedRecordings = {
            ...this.fetchedRecordings,
            [obfuscatedId]: URL.createObjectURL(audioBlob)
          }
        })
        .finally(() => {
          const index = this.loadingRecordings.findIndex((recordingId) => recordingId === obfuscatedId)
          this.loadingRecordings.splice(index, 1)
        })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    reload() {
      this.$refs.table.reload()
    },
    humanize
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
          if (this.hideTable) {
            this.hideTable = false
            return
          }

          this.$nextTick(() => {
            this.currentPage = 1
            this.reload()
          })
        }, 500)
      },
      deep: true
    }
  }
}
</script>

<template>
  <div ref="addressFormFieldNode" class="form-field mb-3">
    <label class="mb-0">
      Address<span class="text-danger">*</span>
    </label>

    <div>
      <div class="my-2 btn-group btn-group-toggle" data-toggle="buttons">
        <label :class="['btn btn-xs', { 'btn-primary': internalAddressSource === ADDRESS_SOURCES.FROM_OFFICE }]">
          <input type="radio" :disabled="isFieldDisabled" name="internalAddressSource"
            v-model="internalAddressSource"
            :value="ADDRESS_SOURCES.FROM_OFFICE">
          From Office
        </label>
        <label :class="['btn btn-xs', { 'btn-primary': internalAddressSource === ADDRESS_SOURCES.GOOGLE_AUTOCOMPLETE }]">
          <input type="radio" :disabled="isFieldDisabled" name="internalAddressSource"
            v-model="internalAddressSource"
            :value="ADDRESS_SOURCES.GOOGLE_AUTOCOMPLETE">
          Select Address
        </label>
        <label v-if="checkin.addressSource.toUpperCase() === ADDRESS_SOURCES.PUBLIC_API_ADDRESS"
          :class="['btn btn-xs', { 'btn-primary': internalAddressSource === ADDRESS_SOURCES.PUBLIC_API_ADDRESS }]">
          <input type="radio" :disabled="isFieldDisabled" name="internalAddressSource"
            v-model="internalAddressSource"
            :value="ADDRESS_SOURCES.PUBLIC_API_ADDRESS">
          Public API Address
        </label>
        <label v-if="stEnabled && checkin.serviceTitanJob"
          :class="['btn btn-xs', { 'btn-primary': internalAddressSource === ADDRESS_SOURCES.FROM_ST_JOB }]">
          <input type="radio" :disabled="isFieldDisabled" name="internalAddressSource"
            v-model="internalAddressSource"
            :value="ADDRESS_SOURCES.FROM_ST_JOB">
          From ServiceTitan Job
        </label>
      </div>
    </div>

    <div v-if="internalAddressSource === ADDRESS_SOURCES.GOOGLE_AUTOCOMPLETE">
      <InputAutocomplete
        v-if="showMap"
        ref="inputAutocomplete"
        :google-map-api-key="googleMapApiKey"
        :disabled="isFieldDisabled"
        :init-address="formattedAddress"
        :options="optionAutocomplete"
        @keydown.native.enter.prevent
        @updateAddress="updateGoogleAddress" />
      <div v-if="isAddressNotSelected" class="mt-2 small text-warning">Select the address from the dropdown to apply it</div>
    </div>

    <div v-if="internalAddressSource === ADDRESS_SOURCES.FROM_ST_JOB">
      {{ checkin.serviceTitanJob.formattedAddress }}
    </div>

    <div v-if="internalAddressSource === ADDRESS_SOURCES.PUBLIC_API_ADDRESS">
      {{ checkin.formattedAddress }}
    </div>
  </div>
</template>

<script>
import InputAutocomplete from '../../../components/google_address_autocomplete'

import { ADDRESS_SOURCES } from '../helpers'

export default {
  components: {
    InputAutocomplete
  },
  props: {
    checkin: { type: Object, required: true },
    addressSource: { type: String, required: true, validator: (source) => Object.keys(ADDRESS_SOURCES).includes(source) },
    formattedAddress: { type: String },
    stEnabled: { type: Boolean, default: false },
    showMap: { type: Boolean, required: true },
    isAddressNotSelected: { type: Boolean, required: true },
    googleMapApiKey: { type: String, required: true },
    isFieldDisabled: { type: Boolean, required: true }
  },
  computed: {
    internalAddressSource: {
      get() {
        return this.addressSource
      },
      set(source) {
        this.$emit('input', { source })
      }
    }
  },
  methods: {
    updateGoogleAddress(foundAddress) {
      this.$emit('input', { source: this.ADDRESS_SOURCES.GOOGLE_AUTOCOMPLETE, foundAddress })
    }
  },
  created() {
    this.ADDRESS_SOURCES = ADDRESS_SOURCES
    this.optionAutocomplete = {
      lang: 'en',
      fields: ['formatted_address', 'geometry', 'address_components'],
      types: ['address']
    }
  }
}
</script>

import SettingsStep from '../../edit_steps/geobooster_widgets_settings'
import required from '../../validators/required'

export default {
  components: {
    SettingsStep
  },
  data: () => ({
    steps: {
      settings: {
        name: {
          component: 'CustomInput',
          name: 'name',
          value: '',
          label: 'Widget name',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        googleApiKey: {
          component: 'CustomInput',
          name: 'googleApiKey',
          value: '',
          label: 'Google Maps Key',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        googleApiKeyInfo: {
          component: 'InfoPanel',
          name: 'googleApiKeyInfo',
          value: 'Google Maps Key cannot be updated on the fly.\nYou will need to save your changes to see the effect',
          show: false
        },
        googleMapId: {
          component: 'CustomInput',
          name: 'googleMapId',
          value: '',
          label: 'Google map id',
          error: false,
          errorText: ''
        },
        mapHeight: {
          component: 'CustomInput',
          name: 'mapHeight',
          type: 'number',
          value: 300,
          label: 'Map height',
          error: false,
          errorText: '',
          validators: {
            required
          }
        },
        zoom: {
          component: 'CustomSelect',
          name: 'zoom',
          value: '',
          label: 'Map zoom level',
          // this line fills values from 1 to 21
          values: [{
            value: '',
            text: 'Auto'
          }, ...Array(21).fill(0).map((_, index) => ({
            value: index + 1,
            text: index + 1
          }))],
          type: 'number',
          error: false,
          errorText: ''
        },
        showCustomer: {
          component: 'CustomCheckbox',
          name: 'showCustomer',
          value: true,
          label: 'Show customer name',
          error: false,
          errorText: ''
        },
        showAddressDisclamer: {
          component: 'CustomCheckbox',
          name: 'showAddressDisclamer',
          value: true,
          label: 'Show disclaimer below the map that all addresses are obfuscated',
          error: false,
          errorText: ''
        },
        showDate: {
          component: 'CustomCheckbox',
          name: 'showDate',
          value: true,
          label: 'Show moment dates',
          error: false,
          errorText: ''
        },
        hideReviewAuthor: {
          component: 'Radio',
          name: 'hideReviewAuthor',
          value: false,
          values: [
            {
              text: 'Show author',
              value: false
            },
            {
              text: 'Show initials',
              value: true
            },
            {
              text: 'Show custom name',
              value: 'custom'
            }
          ],
          label: 'Obfuscate review authors',
          error: false,
          errorText: ''
        },
        reviewAuthorStub: {
          component: 'CustomInput',
          name: 'reviewAuthorStub',
          value: '',
          placeholder: 'e. g. "Anonymous customer"',
          label: 'Custom text for review author name',
          error: false,
          errorText: '',
          additionalAttrs: {
            maxLength: 30
          }
        },
        momentsPerPage: {
          component: 'CustomInput',
          name: 'momentsPerPage',
          type: 'number',
          value: '',
          label: 'Moments per page',
          error: false,
          errorText: ''
        }
      }
    }
  }),
  computed: {
    googleApiKeyValue() {
      return this.steps.settings.googleApiKey.value
    },
    mapHeightValue() {
      return this.steps.settings.mapHeight.value
    }
  },
  watch: {
    googleApiKeyValue(newValue) {
      this.steps.settings.googleApiKeyInfo.show = this.content.widget.googleApiKey !== newValue
    },
    mapHeightValue(newValue) {
      this.$nextTick(() => {
        if (newValue > 1500) {
          this.steps.settings.mapHeight.value = 1500
        }
      })
    }
  }
}

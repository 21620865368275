<template>
  <div>
    <VueSlimTable
      class="responsive-table borderless-table"
      ref="table"
      :columns="tableColumns"
      :source="tableSource">
      <template #cell:actions="{ row }">
        <a href="#" @click.prevent="onEditTenant(row)">edit</a>
      </template>
    </VueSlimTable>

    <SlindingPanel :show-panel="showPanel" @closeSlidingPanel="onCloseSlidingPanel" position="right">
      <TenantForm @form-cancel="onCloseSlidingPanel" @form-submit-success="onFormSubmitSuccess" :tenant="selectedTenant" />
    </SlindingPanel>

    <button class="btn btn-outline-primary" @click.prevent="showPanel = !showPanel">Create Tenant</button>
  </div>
</template>

<script>
import SlindingPanel from 'vue_widgets/components/sliding_panel'
import axiosTransform from 'common/axios'
import TenantForm from './index/tenant_form'

export default {
  components: {
    SlindingPanel,
    TenantForm
  },
  data() {
    return {
      showPanel: false,
      selectedTenant: undefined
    }
  },
  methods: {
    tableSource(params) {
      return axios.get('/api/service_titan/tenants', { params, ...axiosTransform })
        .then((res) => res.data)
    },
    onCloseSlidingPanel() {
      this.selectedTenant = undefined
      this.showPanel = false
    },
    onEditTenant(tenant) {
      this.selectedTenant = tenant
      this.showPanel = true
    },
    onFormSubmitSuccess() {
      this.onCloseSlidingPanel()
      this.$refs.table.reload()
    }
  },
  created() {
    this.tableColumns = [
      { key: 'title', title: 'Title' },
      { key: 'appKey', title: 'App Key' },
      { key: 'remoteId', title: 'Tenant' },
      { key: 'clientId', title: 'Client ID' },
      { key: 'clientSecret', title: 'Client Secret' },
      { key: 'actions', title: '' }
    ]
  }
}
</script>
